import React from 'react';
import Text from '../components/Text';
import { Menu } from '../types/menu';
import { FaPepperHot } from 'react-icons/fa';
import { titleCase, toEuro } from './../common/utils';
import { CHICKEN } from '../common/constant';

type MenuCardProps = {
  menu: Menu;
  category: string;
  unit: string;
};

const MenuCard = ({ menu, category, unit }: MenuCardProps) => {
  return (
    <div className='flex flex-col gap-2 min-w-[80%] relative sm:flex-row'>
      <div className='flex items-center justify-evenly gap-1'>
        <img
          src={`/${menu.image}`}
          alt={menu.name}
          className='w-[170px] h-[170px] object-cover rounded-xl '
        />
        <div className='flex flex-col items-center justify-between gap-5 sm:hidden'>
          <div className='flex flex-col items-center gap-2 '>
            <h2 className='text-2xl font-bold text-center'>{menu.name}</h2>
            {menu.spicyLevel && (
              <div className='flex'>
                {[...Array(+menu.spicyLevel)].map((_, index) => (
                  <FaPepperHot color='red' key={index} />
                ))}
              </div>
            )}
          </div>
          {/* {category === CHICKEN ? (
            <div className='flex flex-col flex-wrap items-center gap-2'>
              {Object.entries(menu.price).map(([size, price], index) => (
                <span
                  key={index}
                  className='text-xl justify-center flex gap-2 flex-wrap border-b-2 border-dotted border-gray-400'
                >
                  <span>{titleCase(size)}</span>
                  <span>{toEuro(+price)}</span>
                </span>
              ))}
            </div>
          ) : (
            <h2 className='text-2xl'>{toEuro(+menu.price)}</h2>
          )} */}
        </div>
      </div>

      <div className='flex flex-col gap-3 w-full p-3 justify-between'>
        <div className='hidden items-start justify-between sm:flex'>
          <div className='flex items-center gap-5'>
            <h2 className='text-3xl font-bold'>{menu.name}</h2>
            {menu.spicyLevel && (
              <div className='flex'>
                {[...Array(+menu.spicyLevel)].map((_, index) => (
                  <FaPepperHot color='red' key={index} />
                ))}
              </div>
            )}
          </div>
          {/* {category === CHICKEN ? (
            <div className='flex flex-col items-start gap-2 '>
              {Object.entries(menu.price).map(([size, price], index) => (
                <span
                  key={index}
                  className='text-xl justify-center flex gap-2 flex-wrap border-b-2 border-dotted border-gray-400'
                >
                  <span>{titleCase(size)}</span>
                  <span>{toEuro(+price)}</span>
                </span>
              ))}
            </div>
          ) : (
            <h2 className='text-2xl'>{toEuro(+menu.price)}</h2>
          )} */}
        </div>
        <div>
          <p className='my-4 text-xl text-center sm:text-start'>
            <Text tid={menu.description} />
          </p>
          {category === CHICKEN ? (
            <div className='flex gap-4 items-center justify-center sm:justify-start'>
              {Object.entries(menu.size).map(
                ([size, amount]: [string, number], index) => (
                  <span
                    key={index}
                    className='text-md justify-center flex gap-2 flex-wrap  text-gray-500 '
                  >
                    {titleCase(size)} ± {amount}
                    {unit}
                  </span>
                )
              )}
            </div>
          ) : (
            <p className='text-md  text-gray-500  text-center sm:text-start '>
              {`± ${menu.size}`} {unit}
            </p>
          )}
        </div>
      </div>
      {menu.badge && (
        <div className='bg-brand text-white clipped  w-[5rem] h-[5rem] flex justify-center items-center absolute top-[-20%] left-[-5%] badge'>
          <span className='text-center'>{menu.badge}</span>
        </div>
      )}
    </div>
  );
};

export default MenuCard;
