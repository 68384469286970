import React, { useState } from 'react';
import { toEuro } from '../../common/utils';
import { RiDeleteBinFill } from 'react-icons/ri';
import Text from '../Text';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { useSingleOrderContext } from '../context/SingleOrderContext';
import {
  CHICKEN,
  MAX_CHICKEN_ORDER,
  SAUCEAPARTPRICE,
} from '../../common/constant';

interface CartItemProps {
  category: string;
  item: {
    id: string;
    name: string;
    price: number;
    subTotal: number;
    amount: number;
    image: string;
    size?: string;
    isSauceApart?: boolean;
  };
}

const CartItem = ({ category, item }: CartItemProps) => {
  const { order, updateOrder, getTotalChickenAmount, availableAmount } =
    useSingleOrderContext();
  const [showDisable, setShowDisable] = useState(false);

  const onAmountAdd = () => {
    if (category === CHICKEN) {
      const isMoreThanMax = getTotalChickenAmount() >= MAX_CHICKEN_ORDER;
      const isMoreThanLimit = getTotalChickenAmount() >= availableAmount;
      const disableAdd = isMoreThanMax || isMoreThanLimit;

      if (disableAdd) {
        setShowDisable(true);
        return;
      }
      setShowDisable(false);
      const updatedPart = {
        ...order,
        products: {
          ...order.products,
          [category]: order.products[category].map((product: any) =>
            product.id === item.id
              ? {
                  ...product,
                  amount: product.amount + 1,
                  subTotal:
                    product.price * (product.amount + 1) +
                    (product.isSauceApart
                      ? SAUCEAPARTPRICE * (product.amount + 1)
                      : 0),
                }
              : product
          ),
        },
      };
      updateOrder(updatedPart);
    } else {
      const updatedPart = {
        ...order,
        products: {
          ...order.products,
          [category]: order.products[category].map((product: any) =>
            product.id === item.id
              ? {
                  ...product,
                  amount: product.amount + 1,
                  subTotal: product.price * (product.amount + 1),
                }
              : product
          ),
        },
      };
      updateOrder(updatedPart);
    }
  };

  const onAmountSubtract = () => {
    setShowDisable(false);
    if (item.amount - 1 > 0) {
      const updatedPart = {
        ...order,
        products: {
          ...order.products,
          [category]: order.products[category].map((product: any) =>
            product.id === item.id
              ? {
                  ...product,
                  amount: item.amount - 1,
                  subTotal:
                    item.price * (item.amount - 1) +
                    (item.isSauceApart
                      ? SAUCEAPARTPRICE * (item.amount - 1)
                      : 0),
                }
              : product
          ),
        },
      };
      updateOrder(updatedPart);
    } else {
      onDeleteItem();
    }
  };

  const onDeleteItem = () => {
    const updatedPart = {
      ...order,
      products: {
        ...order.products,
        [category]: order.products[category].filter(
          (product: any) => product.id !== item.id
        ),
      },
    };
    updateOrder(updatedPart);
  };

  return (
    <>
      <div className='w-full'>
        <span className='text-xs text-gray-500'>
          <Text tid={category} />
        </span>
        <div className='flex items-center justify-between gap-6 border-b-2 pb-4 border-dotted'>
          <div className='flex gap-2 justify-start items-center grow max-w-[60%]'>
            <img
              src={`/${item.image}`}
              alt={item.name}
              className='w-14 h-14 rounded-lg shadow-md md:w-20 md:h-20'
            />
            <div className='flex flex-col justify-start items-start'>
              <span className='text-lg font-bold'>{item.name}</span>
              <span className='text-sm text-gray-400'>{item.size}</span>
              <span className='text-sm text-gray-400'>
                {item.isSauceApart ? (
                  <div className='flex flex-wrap'>
                    <Text tid='sauceApart' />{' '}
                    <span>+ {toEuro(SAUCEAPARTPRICE)}</span>
                  </div>
                ) : (
                  ''
                )}
              </span>
              <span className='text-sm font-bold '>
                {toEuro(item.subTotal)}
              </span>
            </div>
          </div>
          <div className='flex flex-col justify-center items-start '>
            <div className='flex items-center gap-3 '>
              <button onClick={onAmountSubtract} type='button'>
                <FaMinus size={10} />
              </button>
              <span className='w-5 text-center text-lg'>{item.amount}</span>
              <button
                className={showDisable ? 'opacity-20' : 'none'}
                disabled={showDisable}
                onClick={onAmountAdd}
                type='button'
              >
                <FaPlus size={10} />
              </button>
            </div>
          </div>
          <button onClick={onDeleteItem} type='button'>
            <RiDeleteBinFill size={25} color='red' />
          </button>
        </div>
      </div>
    </>
  );
};

export default CartItem;
