import React, { useState, useEffect } from 'react';
import Text from '../../../components/Text';
import validator from 'validator';
import WizardButton from './WizardButton';
import { Customer } from '../../../types/customer';
import { useSingleOrderContext } from '../../../components/context/SingleOrderContext';

const CustomerForm = ({
  handleClick,
}: {
  handleClick: (step: string) => void;
}) => {
  const { customer, setCustomer } = useSingleOrderContext();

  const [orderCustomer, setOrderCustomer] = useState<Customer>(undefined);
  const [emailError, setEmailError] = useState(undefined);
  const [phoneError, setPhoneError] = useState(undefined);
  const [firstnameError, setFirstnameError] = useState(undefined);
  const [lastnameError, setLastnameError] = useState(undefined);
  const [disableNext, setDisableNext] = useState(true);

  useEffect(() => {
    if (Object.keys(customer).length > 0) {
      setOrderCustomer(customer);
    }
  }, []);

  useEffect(() => {
    orderCustomer?.firstname &&
    orderCustomer?.lastname &&
    orderCustomer?.email &&
    orderCustomer?.phone &&
    emailError === undefined &&
    phoneError === undefined &&
    firstnameError === undefined &&
    lastnameError === undefined
      ? setDisableNext(false)
      : setDisableNext(true);
  }, [orderCustomer]);

  const handleChange = (e: any) => {
    const { id, value } = e.target;
    switch (id) {
      case 'email':
        !validator.isEmail(value)
          ? setEmailError('Enter valid Email!')
          : setEmailError(undefined);
        break;
      case 'phone':
        !validator.isMobilePhone(value)
          ? setPhoneError('Enter valid Phone Number!')
          : setPhoneError(undefined);
        break;
      case 'firstname':
        validator.isEmpty(value)
          ? setFirstnameError('Enter your First name!')
          : setFirstnameError(undefined);
        break;
      case 'lastname':
        validator.isEmpty(value)
          ? setLastnameError('Enter your Last name!')
          : setLastnameError(undefined);
        break;
      default:
        new Error('Invalid input');
    }

    setOrderCustomer((customer) => ({
      ...customer,
      [id]: value,
    }));
  };

  const saveCustomer = () => {
    setCustomer(orderCustomer);
  };

  return (
    <div className='w-full max-w-sm mx-auto'>
      <form className='bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col gap-5 '>
        <div>
          <label
            className='block text-gray-700 text-sm font-bold mb-2'
            htmlFor='firstname'
          >
            <Text tid='firstname' />
            <abbr className='required' title='required'>
              *
            </abbr>
          </label>
          <input
            className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
              firstnameError && ' border border-red-500'
            }`}
            id='firstname'
            name='firstname'
            type='text'
            defaultValue={orderCustomer?.firstname}
            required
            onChange={handleChange}
          ></input>
          {firstnameError?.length > 0 && (
            <p className='text-red-500 text-xs italic'>{firstnameError}</p>
          )}
        </div>
        <div>
          <label
            className='block text-gray-700 text-sm font-bold mb-2'
            htmlFor='lastname'
          >
            <Text tid='lastname' />
            <abbr className='required' title='required'>
              *
            </abbr>
          </label>
          <input
            className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
              lastnameError && ' border border-red-500'
            }`}
            id='lastname'
            name='lastname'
            type='text'
            defaultValue={orderCustomer?.lastname}
            required
            onChange={handleChange}
          ></input>
          {lastnameError?.length > 0 && (
            <p className='text-red-500 text-xs italic'>{lastnameError}</p>
          )}
        </div>
        <div>
          <label
            className='block text-gray-700 text-sm font-bold mb-2'
            htmlFor='phone'
          >
            <Text tid='phone' />
            <abbr className='required' title='required'>
              *
            </abbr>
          </label>
          <input
            className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
              phoneError && ' border border-red-500'
            }`}
            id='phone'
            name='phone'
            defaultValue={orderCustomer?.phone}
            type='tel'
            required
            onChange={handleChange}
          ></input>
          {phoneError?.length > 0 && (
            <p className='text-red-500 text-xs italic'>{phoneError}</p>
          )}
        </div>
        <div>
          <label
            className='block text-gray-700 text-sm font-bold mb-2'
            htmlFor='email'
          >
            <Text tid='email' />
            <abbr className='required' title='required'>
              *
            </abbr>
          </label>
          <input
            className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
              emailError && ' border border-red-500'
            }`}
            id='email'
            name='email'
            type='email'
            defaultValue={orderCustomer?.email}
            required
            onChange={handleChange}
          ></input>
          {emailError?.length > 0 && (
            <p className='text-red-500 text-xs italic'>{emailError}</p>
          )}
        </div>

        {/* <div>
          <label
            className='block text-gray-700 text-sm font-bold mb-2'
            htmlFor='orderNotes'
          >
            <Text tid='orderNotes' /> (Optional)
          </label>
          <textarea
            className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
            id='orderNotes'
            name='orderNotes'
            required
            onChange={handleChange}
          ></textarea>
        </div> */}
      </form>
      <WizardButton
        handleClick={handleClick}
        disabled={disableNext}
        middleCallback={saveCustomer}
        warningMessage='userFormWarning'
      />
    </div>
  );
};

export default CustomerForm;
