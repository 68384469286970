import React, { useContext, useState, useEffect } from "react";
import Text from "../components/Text";
import { LanguageContext } from "../hooks/LanguageContext";
import { policyDutch, policyEnglish } from "./../data/policyData";
import { Helmet } from "react-helmet-async";

const Policy = () => {
  const { userLanguage } = useContext(LanguageContext);
  const [data, setData] = useState([]);

  useEffect(() => {
    userLanguage === "GB" ? setData(policyEnglish) : setData(policyDutch);
  }, [userLanguage]);
  return (
    <>
      <Helmet>
        <title>Policy</title>
        <meta
          name="description"
          content="Ons team van Chimek is gepassioneerd over eten en is toegewijd aan het bieden van uitstekende klantenservice."
        />
        <link rel="canonical" href="/policy" />
      </Helmet>

      <div className="my-[90px]">
        <div className=" h-[17vh] flex justify-center items-center md:h-[25vh] ">
          <h1 className="text-3xl md:text-5xl font-bold">
            <Text tid="policy" />
          </h1>
        </div>

        <div className=" md:px-[10rem] max-w-[80%] mx-auto">
          {data.map((row, index) => (
            <div key={index} className="mb-5">
              <p className="text-lg font-bold">{row.title}</p>
              <p>{row.desc}</p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Policy;
