import React, { useEffect, useState } from 'react';
import {
  PaymentElement,
  LinkAuthenticationElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { Layout } from '@stripe/stripe-js';
import Text from '../../../components/Text';
import { ColorRing } from 'react-loader-spinner';
import { GET_PORT } from '../../../common/constant';
import { addOrder } from '../../../api/firebase';
import { useNavigate } from 'react-router-dom';
import Alert from '../../../components/Alert';
import { useSingleOrderContext } from '../../../components/context/SingleOrderContext';

const CheckoutForm = () => {
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(undefined);
  const [consent, setConsent] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const { order, customer } = useSingleOrderContext();

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const intenTclientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret'
    );

    if (!intenTclientSecret) {
      return;
    }
    stripe
      .retrievePaymentIntent(intenTclientSecret)
      .then(({ paymentIntent }) => {
        switch (paymentIntent.status) {
          case 'succeeded':
            setMessage('Payment succeeded!');
            break;
          case 'processing':
            setMessage('Your payment is processing.');
            break;
          case 'requires_payment_method':
            setMessage('Your payment was not successful, please try again.');
            break;
          default:
            setMessage('Something went wrong.');
            break;
        }
      });
  }, [stripe]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!consent) {
      setShowWarning(true);
      return;
    }

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      setError(<Text tid='serverErrorInformation' />);
      return;
    }

    setIsLoading(true);
    try {
      await addOrder({ order, customer });
    } catch (err) {
      setError(<Text tid='serverErrorInformation' />);
      // send to transaction fail page.
      setIsLoading(false);
      return;
    }

    const { error: stripeError } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${GET_PORT()}/orderpayment/transaction?`,
      },
    });

    if (stripeError) {
      if (error.type === 'card_error' || error.type === 'validation_error') {
        setMessage(error.message);
      } else {
        setMessage('An unexpected error occurred.');
      }
    }

    setIsLoading(false);
  };

  const tabs: Layout = 'tabs';
  const paymentElementOptions = {
    layout: tabs,
    defaultValues: {
      billingDetails: {
        email: customer.email,
        name: `${customer.firstname} ${customer.lastname}`,
        phone: customer.phone,
      },
    },
  };

  const toHome = () => {
    navigate(`/`);
  };

  const consentPolicy = () => {
    if (!consent) {
      setShowWarning(false);
    }
    setConsent(!consent);
  };

  return (
    <>
      {error && (
        <div className='flex flex-col justify-center items-center gap-10'>
          <span className='text-xl font-semibold'>{error}</span>
          <button
            className='bg-brand text-white rounded-lg p-4 h-9 flex items-center justify-center hover:scale-105 duration-100 shadow-md hover:shadow-fontColor'
            onClick={toHome}
          >
            <Text tid='toOrderFirstStep' />
          </button>
        </div>
      )}
      {!error && (
        <form
          id='payment-form'
          onSubmit={handleSubmit}
          className='max-w-[400px]'
        >
          <LinkAuthenticationElement id='link-authentication-element' />
          <PaymentElement
            id='payment-element'
            options={paymentElementOptions}
          />
          <hr className='border-t-2 border-gray-200 border-solid my-5' />
          <div className='mt-5'>
            <p className='text-sm  '>
              <Text tid='personalDataInform' />{' '}
              <a href='/policy' target='_blank' className='text-brand'>
                <Text tid='policy' />
              </a>
              .
            </p>
            <div className='mt-7'>
              <div className='flex items-baseline mr-4 rounded-md p-2'>
                <input
                  id='checkbox'
                  type='checkbox'
                  checked={consent}
                  onChange={consentPolicy}
                  className='w-4 h-4 text-brand bg-gray-100 border-gray-300 rounded  focus:ring-brand dark:focus:ring-brand dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
                />
                <label htmlFor='checkbox' className='ml-2 text-md'>
                  <Text tid='consent' />{' '}
                  <a href='/policy' target='_blank' className='text-brand '>
                    <Text tid='terms' />
                  </a>{' '}
                  <abbr className='required' title='required'>
                    *
                  </abbr>
                </label>
              </div>
              <span></span>
            </div>
          </div>
          {showWarning && <Alert subject='' description='consentWarning' />}
          <button
            className='bg-brand w-full rounded-xl p-3 text-white my-5 flex justify-center'
            disabled={isLoading || !stripe || !elements}
            id='submit'
          >
            <span id='button-text'>
              {isLoading ? (
                <ColorRing
                  visible={true}
                  height='30'
                  width='30'
                  ariaLabel='blocks-loading'
                  wrapperClass='blocks-wrapper'
                  colors={[
                    '#8a0917',
                    '#fcdd89',
                    '#f8b26a',
                    '#abbd81',
                    '#8a0917',
                  ]}
                />
              ) : (
                <Text tid='payNow' />
              )}
            </span>
          </button>
          {/* Show any error or success messages */}
          {message && <div id='payment-message'>{message}</div>}
        </form>
      )}
    </>
  );
};

export default CheckoutForm;
