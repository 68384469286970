import React, { createContext, useContext, useState } from 'react';
import { CHICKEN } from './../../common/constant';

const OrderContext = createContext<any>([]);

export function OrderContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [orderList, setOrderList] = useState([]);
  const [customer, setCustomer] = useState({});
  const [isOrderInfoModalSeen, setIsOrderInfoModalSeen] = useState(false);

  const updateOrder = (order: []) => {
    setOrderList(order);
  };

  const getTotalChickenAmount = () => {
    let total = 0;
    orderList.forEach((order) => {
      order.products?.hasOwnProperty(CHICKEN) &&
        order.products[CHICKEN].forEach((menu: any) => {
          total += menu.amount;
        });
    });
    return total;
  };

  const getChickenProductAmountPerDay = (orderDayId: string) => {
    let total = 0;
    const order = orderList.find((order) => order.dateId === orderDayId);
    if (!order) return 0;
    order.products?.hasOwnProperty(CHICKEN) &&
      order.products[CHICKEN].forEach((menu: any) => {
        total += menu.amount;
      });

    return total;
  };
  const getChickenProductPricePerDay = (orderDayId: string) => {
    let total = 0;
    const order = orderList.find((order) => order.dateId === orderDayId);
    if (!order) return 0;
    order.products?.hasOwnProperty(CHICKEN) &&
      order.products[CHICKEN].forEach((item: any) => {
        total += +item.price * item.amount;
      });

    return total;
  };

  const getAmountByMenuIdAndDateId = (menuId: string, dateId: string) => {
    const order = orderList.find((order) => order.dateId === dateId);
    if (!order) return 0;
    const menu =
      order.products?.hasOwnProperty(CHICKEN) &&
      order.products[CHICKEN].find((menu: any) => menu.id === menuId);

    return menu ? menu.amount : 0;
  };

  const getOrderDateById = (dateId: string) => {
    return orderList.find((order) => order.dateId === dateId).date;
  };

  const getOrderDays = () => {
    return orderList.map((order) => order.dateId);
  };

  const getTotalPrice = (): number => {
    let total = 0;
    orderList.forEach((order: any) => {
      order.products[CHICKEN].forEach((item: any) => {
        total += +item.price * item.amount;
      });
    });
    return total;
  };

  return (
    <OrderContext.Provider
      value={{
        orderList: orderList.sort((a, b) => a.dateId - b.dateId),
        updateOrder,
        getChickenProductAmountPerDay,
        getChickenProductPricePerDay,
        getTotalChickenAmount,
        getOrderDays,
        getAmountByMenuIdAndDateId,
        getOrderDateById,
        getTotalPrice,
        customer,
        setCustomer,
        setIsOrderInfoModalSeen,
        isOrderInfoModalSeen,
      }}
    >
      {children}
    </OrderContext.Provider>
  );
}

export function useOrderContext() {
  return useContext(OrderContext);
}
