import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import {
  addOrderAndUdateSchedule,
  getOrderScheduleByDateId,
  updateOrderAndUdateSchedule,
} from '../../../api/firebase';
import Text from '../../../components/Text';

import {
  countSlotOrderAmount,
  timeStampToString,
  toEuro,
} from './../../../common/utils';
import { Order } from '../../../types/order';
import {
  CHICKEN,
  DRINK,
  SAUCE,
  SERVER_ERR_MSG,
  SIDEMENU,
  SUCCEEDED,
} from '../../../common/constant';
import { v4 as uuid } from 'uuid';
import TabView from '../../order/components/menuSelction/TabView';
import CartItem from '../../../components/cart/CartItem';
import { useSingleOrderContext } from '../../../components/context/SingleOrderContext';

interface AddOrderFormProps {
  handleClose: () => void;
  dateId: string;
  order: Order;
  isEdit: boolean;
  setError: (err: any) => void;
}

const AddOrderForm = ({
  handleClose,
  dateId,
  order,
  isEdit,
  setError,
}: AddOrderFormProps) => {
  const [firstname, setFirstname] = useState(order?.customer?.firstname || '');
  const [lastname, setLastname] = useState(order?.customer?.lastname || '');
  const [email, setEmail] = useState(order?.customer?.email || '');
  const [phone, setPhone] = useState(order?.customer?.phone || '');
  const [pickupTime, setPickupTime] = useState(order?.pickupTime || '');
  const [prevPickupTime, setPrevPickupTime] = useState(order?.pickupTime || '');
  const [paymentStatus, setPaymentStatus] = useState(
    order?.paymentStatus || ''
  );
  const [totalPaidAmount, setTotalPaidAmount] = useState<number>(
    order?.totalPaidAmount || undefined
  );
  const [prevTotalChickenBoxCount, setPrevTotalChickenBoxCount] =
    useState<number>(order?.totalChickenBoxCount || 0);
  const [paymentMethod, setPaymentMethod] = useState(
    order?.paymentMethod || ''
  );
  const [products, setProducts] = useState(order?.products || undefined);
  const {
    isLoading: scheduleLoading,
    error: scheduleError,
    data: schedule,
  }: any = useQuery(['schedule'], () => getOrderScheduleByDateId(dateId));

  const { getTotalProductAmount, getOrderTotalPrice, getTotalChickenAmount } =
    useSingleOrderContext();

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (getTotalProductAmount() === 0) return;

    const orderData = {
      customer: {
        firstname,
        lastname,
        email,
        phone,
      },
      date: timeStampToString(+dateId),
      dateId: +dateId,
      paymentMethod,
      paymentStatus,
      pickupTime,
      products: order.products,
      totalPaidAmount,
      currentOrderPrice: getOrderTotalPrice(),
      paid: paymentStatus === SUCCEEDED,
      totalChickenBoxCount: getTotalChickenAmount(),
    };

    if (isEdit) {
      const shouldUpdateTimeslots =
        prevPickupTime !== pickupTime ||
        prevTotalChickenBoxCount !== orderData.totalChickenBoxCount;
      updateOrderAndUdateSchedule({
        id: order.id,
        orderData: orderData,
        shouldUpdateTimeslots: shouldUpdateTimeslots,
        prevPickupTime: prevPickupTime,
        updatedPickupTime: pickupTime,
        prevTotalAmount: prevTotalChickenBoxCount,
        updatedTotalAmount: orderData.totalChickenBoxCount,
      }).catch((err) => {
        setError(err);
      });
    } else {
      addOrderAndUdateSchedule({ order: orderData, id: uuid() }).catch(
        (err) => {
          setError(err);
        }
      );
    }

    handleClose();
  };

  return (
    <form
      onSubmit={handleSubmit}
      className='bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col gap-5'
    >
      {scheduleError && (
        <h2 className='text-2xl text-center'>{SERVER_ERR_MSG}</h2>
      )}
      <div>
        <label
          className='block text-gray-700 text-sm font-bold mb-2'
          htmlFor='products'
        >
          Products
        </label>
        <div className='flex flex-wrap justify-center gap-5'>
          <TabView />
        </div>
      </div>
      <hr />
      {order?.products && (
        <div>
          {order.products?.hasOwnProperty(CHICKEN) &&
            order.products[CHICKEN].map((product: any, index: number) => (
              <CartItem
                item={product}
                key={product.id + index}
                category={CHICKEN}
              />
            ))}
          {order.products?.hasOwnProperty(SIDEMENU) &&
            order.products[SIDEMENU].map((product: any) => (
              <CartItem item={product} key={product.id} category={SIDEMENU} />
            ))}
          {order.products?.hasOwnProperty(SAUCE) &&
            order.products[SAUCE].map((product: any) => (
              <CartItem item={product} key={product.id} category={SAUCE} />
            ))}
          {order.products?.hasOwnProperty(DRINK) &&
            order.products[DRINK].map((product: any) => (
              <CartItem item={product} key={product.id} category={DRINK} />
            ))}
        </div>
      )}

      <div className='w-[30%] min-w-[200px]'>
        <label
          className='block text-gray-700 text-sm font-bold mb-2'
          htmlFor='pickupTime'
        >
          Pick-up time
        </label>
        {!scheduleLoading && (
          <select
            name='pickupTime'
            id='pickupTime'
            value={pickupTime}
            required
            onChange={(e) => setPickupTime(e.target.value)}
            className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
          >
            <option value={undefined}></option>
            {Object.keys(schedule.timeslots).map((key, index) => (
              <option key={key + index} value={key}>
                {key} - {countSlotOrderAmount(schedule.timeslots[key])} reserved
              </option>
            ))}
          </select>
        )}
      </div>

      <div className='flex gap-5 flex-wrap'>
        <div>
          <label
            className='block text-gray-700 text-sm font-bold mb-2'
            htmlFor='firstname'
          >
            Customer First Name
          </label>
          <input
            className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
            id='firstname'
            name='firstname'
            type='text'
            value={firstname}
            required
            onChange={(e) => setFirstname(e.target.value)}
          ></input>
        </div>
        <div>
          <label
            className='block text-gray-700 text-sm font-bold mb-2'
            htmlFor='lastname'
          >
            Customer Last Name
          </label>
          <input
            className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
            id='lastname'
            name='lastname'
            type='text'
            value={lastname}
            required
            onChange={(e) => setLastname(e.target.value)}
          ></input>
        </div>
      </div>
      <div className='flex gap-5  flex-wrap'>
        <div>
          <label
            className='block text-gray-700 text-sm font-bold mb-2'
            htmlFor='email'
          >
            Customer email
          </label>
          <input
            className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
            id='email'
            name='email'
            type='email'
            value={email}
            required
            onChange={(e) => setEmail(e.target.value)}
          ></input>
        </div>
        <div>
          <label
            className='block text-gray-700 text-sm font-bold mb-2'
            htmlFor='phone'
          >
            Customer phone
          </label>
          <input
            className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
            id='phone'
            name='phone'
            type='number'
            value={phone}
            required
            onChange={(e) => setPhone(e.target.value)}
          ></input>
        </div>
      </div>

      <div className='flex gap-5  flex-wrap'>
        {order.products && (
          <div className='w-full text-brand'>
            <span>Total order price is {toEuro(+getOrderTotalPrice())} </span>
          </div>
        )}
        <div>
          <label
            className='block text-gray-700 text-sm font-bold mb-2'
            htmlFor='phone'
          >
            Payment Method
          </label>
          <select
            name='paymentMethod'
            id='paymentMethod'
            value={paymentMethod}
            required
            onChange={(e) => setPaymentMethod(e.target.value)}
            className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
          >
            <option value={undefined}></option>
            <option value='cash'>Cash</option>
            <option value='tikkie'>Tikkie</option>
            <option value='ideal'>Ideal</option>
          </select>
        </div>
        <div>
          <label
            className='block text-gray-700 text-sm font-bold mb-2'
            htmlFor='totalPaidAmount'
          >
            Paid Amount
          </label>
          <input
            className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
            id='totalPaidAmount'
            name='totalPaidAmount'
            type='number'
            defaultValue={totalPaidAmount}
            step={0.01}
            required
            onChange={(e) => setTotalPaidAmount(+e.target.value)}
          ></input>
        </div>

        <div>
          <label
            className='block text-gray-700 text-sm font-bold mb-2'
            htmlFor='paymentStatus'
          >
            Payment Status
          </label>
          <select
            name='paymentStatus'
            id='paymentStatus'
            value={paymentStatus}
            required
            onChange={(e) => setPaymentStatus(e.target.value)}
            className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
          >
            <option value={undefined}></option>
            <option value='succeeded'>Succeedded</option>
            <option value='notCompleted'>Not Completed</option>
          </select>
        </div>
        <div className='flex justify-center gap-5 w-full'>
          <button
            className='text-brand rounded-lg font-bold p-2 w-[30%] min-w-[200px]  border border-brand'
            onClick={handleClose}
          >
            <Text tid='cancel' />
          </button>
          <button
            className='text-white rounded-lg font-bold  bg-brand p-2 w-[30%] min-w-[200px] '
            type='submit'
          >
            {order ? <Text tid='update' /> : <Text tid='save' />}
          </button>
        </div>
      </div>
    </form>
  );
};

export default AddOrderForm;
