import * as React from 'react';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { GiChickenOven, GiBowlOfRice, GiSaucepan } from 'react-icons/gi';
import { BiSolidDrink } from 'react-icons/bi';
import Text from '../../../../components/Text';
import ChickenTabPanel from './ChickenTabPanel';
import SideTabPanel from './SideTabPanel';
import SauceTanPanel from './SauceTanPanel';
import DrinkTabPanel from './DrinkTabPanel';

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <>{children}</>
        </Box>
      )}
    </div>
  );
}

export default function TabView() {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  return (
    <Box>
      <div className='flex justify-between items-center mb-3'>
        <div
          onClick={() => setValue(0)}
          className={`py-2 flex flex-wrap gap-2 grow items-center justify-center text-xl font-extrabold ${
            value === 0 ? 'text-brand border-b-2 border-brand' : 'text-gray-400'
          } max-md:text-sm`}
        >
          <Text tid='chicken' />
          <GiChickenOven size={40} />
        </div>
        <div
          onClick={() => setValue(1)}
          className={`py-2 flex flex-wrap gap-2 grow items-center justify-center text-xl font-extrabold ${
            value === 1 ? 'text-brand border-b-2 border-brand' : 'text-gray-400'
          }  max-md:text-sm `}
        >
          <Text tid='sides' />
          <GiBowlOfRice size={40} />
        </div>
        <div
          onClick={() => setValue(2)}
          className={`py-2 flex flex-wrap gap-2 grow items-center justify-center text-xl font-extrabold ${
            value === 2 ? 'text-brand border-b-2 border-brand' : 'text-gray-400'
          }  max-md:text-sm `}
        >
          <Text tid='sauces' />
          <GiSaucepan size={40} />
        </div>
        <div
          onClick={() => setValue(3)}
          className={`py-2 flex flex-wrap gap-2 grow items-center justify-center text-xl font-extrabold ${
            value === 3 ? 'text-brand border-b-2 border-brand' : 'text-gray-400'
          }  max-md:text-sm `}
        >
          <Text tid='drinks' />
          <BiSolidDrink size={40} />
        </div>
      </div>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <ChickenTabPanel />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <SideTabPanel />
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <SauceTanPanel />
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
          <DrinkTabPanel />
        </TabPanel>
      </SwipeableViews>
    </Box>
  );
}
