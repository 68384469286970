import React from 'react';
import { Collapse } from 'react-collapse';

type AccordionItemProps = {
  open: boolean;
  toggle: () => void;
  title: string;
  desc: string;
};

const AccordionItem = ({ open, toggle, title, desc }: AccordionItemProps) => {
  return (
    <div className='border '>
      <h2 className='mb-0' id='headingOne'>
        <button
          className='group relative flex w-full items-center rounded-t-[15px] border-0 bg-white py-4 px-5 text-left text-base text-neutral-800 transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none dark:bg-neutral-800 dark:text-white'
          type='button'
          onClick={toggle}
        >
          <p className='text-lg font-semibold'>{title}</p>
          <span
            className={`ml-auto h-5 w-5 shrink-0 rotate-[0deg] transition-transform duration-200 ease-in-out  motion-reduce:transition-none dark:fill-blue-300 ${
              open && 'rotate-[180deg] fill-[#336dec]'
            }`}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth='1.5'
              stroke='currentColor'
              className='h-6 w-6'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M19.5 8.25l-7.5 7.5-7.5-7.5'
              />
            </svg>
          </span>
        </button>
      </h2>
      <Collapse isOpened={open}>
        <div className='py-4 px-5'>{desc}</div>
      </Collapse>
    </div>
  );
};

export default AccordionItem;
