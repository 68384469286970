import React, { useContext } from 'react';
import { LanguageContext } from './../hooks/LanguageContext';
import ReactFlagsSelect from 'react-flags-select';

const LanguageSelector = () => {
  const { userLanguage, userLanguageChange } = useContext(LanguageContext);

  const handleLanguageChange = (e: any) => userLanguageChange(e);
  return (
    <ReactFlagsSelect
      countries={['GB', 'NL']}
      customLabels={{ GB: 'EN', NL: 'NL' }}
      placeholder='Language'
      selected={userLanguage}
      onSelect={(code) => handleLanguageChange(code)}
      className='bg-white text-black md:bg-transparent rounded-lg'
      optionsSize={15}
    />
  );
};

export default LanguageSelector;
