import React from 'react';
import { SAUCE, SERVER_ERR_MSG } from '../../../../common/constant';
import { useQuery } from '@tanstack/react-query';
import { getMenu } from '../../../../api/firebase';
import { ColorRing } from 'react-loader-spinner';
import MenuChoice from './MenuChoice';
import { Menu } from '../../../../types/menu';
import Text from '../../../../components/Text';

const SauceTanPanel = () => {
  const {
    isLoading,
    error: menuError,
    data: menuList,
  }: any = useQuery(['sauce'], () => getMenu(SAUCE));
  return (
    <div className='flex flex-wrap gap-10 justify-center items-center mt-5 max-sm:flex-col max-sm:gap-[70px]'>
      {isLoading && (
        <ColorRing
          visible={true}
          height='80'
          width='80'
          ariaLabel='blocks-loading'
          wrapperClass='blocks-wrapper'
          colors={['#8a0917', '#fcdd89', '#f8b26a', '#abbd81', '#8a0917']}
        />
      )}
      {menuList?.length === 0 && (
        <div className='h-[100px] flex items-center  justify-center md:h-[200px]'>
          <h2 className='text-sm text-center justify-center text-gray-500 md:text-lg'>
            <Text tid='noProduct' />
          </h2>
        </div>
      )}
      {menuList &&
        menuList.map((menu: Menu) => (
          <MenuChoice category={SAUCE} menu={menu} key={menu.id} unit='box' />
        ))}
      {menuError && <h2 className='text-2xl text-center'>{SERVER_ERR_MSG}</h2>}
    </div>
  );
};

export default SauceTanPanel;
