import React, { useState, useEffect } from 'react';
import { Menu } from '../../../../types/menu';
import { FaMinus, FaPepperHot, FaPlus } from 'react-icons/fa';
import { useOrderContext } from '../../../../components/context/OrderContext';
import { CHICKEN, MAX_CHICKEN_ORDER, SAUCE } from '../../../../common/constant';
import { ColorRing } from 'react-loader-spinner';
import { titleCase, toEuro } from '../../../../common/utils';
import { useSingleOrderContext } from '../../../../components/context/SingleOrderContext';
import Text from '../../../../components/Text';

type MenuChoiceProps = {
  menu: Menu;
  category: string;
  onButtonClick?: () => void;
  unit?: string | JSX.Element;
};

const MenuChoice = ({
  menu,
  category,
  onButtonClick,
  unit,
}: MenuChoiceProps) => {
  const {
    order,
    updateOrder,
    getAmountByMenuId,
    availableAmount,
    isMenuInOrder,
  } = useSingleOrderContext();

  const [amount, setAmount] = useState(getAmountByMenuId(category, menu.id));
  const [orderDisabled, setOrderDisabled] = useState(false);

  useEffect(() => {
    if (category === CHICKEN) return;
    if (!isMenuInOrder(category, menu.id) && amount === 0) return;
    const updatedPart = order.products?.hasOwnProperty(category)
      ? amount > 0
        ? {
            ...order,
            products: {
              ...order.products,
              [category]: [
                ...order.products[category].filter(
                  (item: any) => item.id !== menu.id
                ),
                {
                  id: menu.id,
                  name: menu.name,
                  price: +menu.price,
                  subTotal: +menu.price * amount,
                  amount,
                  image: menu.image,
                },
              ],
            },
          }
        : {
            ...order,
            products: {
              ...order.products,
              [category]: [
                ...order.products[category].filter(
                  (item: any) => item.id !== menu.id
                ),
              ],
            },
          }
      : {
          ...order,
          products: {
            ...order.products,
            [category]: [
              {
                id: menu.id,
                name: menu.name,
                price: +menu.price,
                subTotal: +menu.price * amount,
                amount,
                image: menu.image,
              },
            ],
          },
        };
    updateOrder(updatedPart);
  }, [amount]);

  return (
    <div className='flex flex-col items-center gap-1 '>
      {availableAmount === undefined && (
        <ColorRing
          visible={true}
          height='80'
          width='80'
          ariaLabel='blocks-loading'
          wrapperClass='blocks-wrapper'
          colors={['#8a0917', '#fcdd89', '#f8b26a', '#abbd81', '#8a0917']}
        />
      )}

      {availableAmount && (
        <div className={`flex flex-col items-center gap-1 py-2 relative`}>
          {menu.sellable === false && (
            <>
              <div className='absolute top-0 right-0 left-0 bottom-0 bg-slate-300 opacity-60 rounded-md'></div>
              <div className='absolute top-0 right-0 left-0 bottom-0 flex flex-col justify-center items-center'>
                <span className='text-2xl font-bold text-brand bg-white p-2 rounded-sm border border-spacing-1 border-brand origin-bottom -rotate-12'>
                  <Text tid='soldOut' />
                </span>
              </div>
            </>
          )}

          <img
            src={`/${menu.image}`}
            alt={menu.image}
            className='w-[140px] h-[140px] rounded-xl shadow-md'
          />
          <div className='flex flex-col justify-center items-center text-2xl max-w-[140px]'>
            {menu.name.includes('&')
              ? menu.name
                  .split(' ')
                  .map((word) => <span key={menu.id + word}>{word}</span>)
              : menu.name}
          </div>
          {category === CHICKEN && (
            <div className='flex'>
              {menu.spicyLevel > 0 ? (
                [...Array(+menu.spicyLevel)].map((e, index) => (
                  <FaPepperHot color='red' key={index} />
                ))
              ) : (
                <FaPepperHot color='transparent' />
              )}
            </div>
          )}
          {category === SAUCE && <span className='flex'>{menu.size} ml</span>}

          {category === CHICKEN ? (
            <div>
              {Object.entries(menu.price).map(([key, value]) => (
                <div
                  key={key}
                  className='flex justify-between items-center gap-2'
                >
                  <span>{titleCase(key)}</span>
                  <span>{`${toEuro(+value)}`}</span>
                </div>
              ))}
            </div>
          ) : (
            <span>
              {`${toEuro(+menu.price)} per `}
              {unit}
            </span>
          )}
          <div
            className={`mt-2 flex  justify-center items-center gap-3 ${
              amount > 0 && 'bg-yellow rounded-xl shadow-md shadow-gray-500'
            }`}
          >
            {category === CHICKEN ? (
              <button
                onClick={onButtonClick}
                className='font-bold min-w-[100px] bg-yellow text-black rounded-lg p-4 h-9 flex items-center justify-center hover:scale-105 duration-100 shadow-md hover:shadow-fontColor'
              >
                <Text tid='addOrder' />
              </button>
            ) : (
              <>
                <button
                  onClick={() => setAmount(amount - 1 > 0 ? amount - 1 : 0)}
                  className='rounded-[50%] text-white bg-brand w-8 h-8 shadow-md shadow-gray-500 flex justify-center items-center'
                  type='button'
                >
                  <FaMinus />
                </button>
                <span className='w-5 text-center text-xl'>{amount}</span>
                <button
                  disabled={orderDisabled}
                  onClick={() => setAmount(amount + 1)}
                  className={`rounded-[50%] text-white bg-brand w-8 h-8 shadow-md shadow-gray-500 flex justify-center items-center first-letter first-letter ${
                    orderDisabled && 'opacity-25 bg-gray-700'
                  }`}
                  type='button'
                >
                  <FaPlus />
                </button>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default MenuChoice;
