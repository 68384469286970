import React, { useState, useEffect } from 'react';
import OrderOverview from './OrderOverview';
import { loadStripe, Appearance } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { ColorRing } from 'react-loader-spinner';
import Text from '../../../components/Text';
import { FaChevronLeft } from 'react-icons/fa';
import { processPayment } from '../../../api/firebase';
import CheckoutForm from './CheckoutForm';
import { useSingleOrderContext } from '../../../components/context/SingleOrderContext';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

type PaymentFormProp = {
  handleClick: (step: string) => void;
  backToFirst: () => void;
};

const PaymentForm = ({ handleClick, backToFirst }: PaymentFormProp) => {
  const [clientSecret, setClientSecret] = useState(undefined);
  const {
    order,
    getTotalChickenAmount,
    getOrderTotalPrice,
    customer,
    updateOrder,
    reset,
  } = useSingleOrderContext();
  const [error, setError] = useState(undefined);
  const [elementReady, setElementReady] = useState(false);

  useEffect(() => {
    if (order === undefined || customer === undefined) {
      setError('Order process went wrong, please try the first order step.');
      return;
    }

    const orderIdWithTotalOrderMount = {
      id: order.id,
      dateId: order.dateId,
      totalChickenBoxCount: getTotalChickenAmount(),
    };
    const orderListWithOrderAmount = {
      ...order,
      totalChickenBoxCount: getTotalChickenAmount(),
      totalOrderPrice: getOrderTotalPrice(),
    };
    updateOrder(orderListWithOrderAmount);

    stripePromise
      .catch((error) => {
        setError(<Text tid='serverErrorInformation' />);
      })
      .finally(() => {
        setElementReady(true);
      });
    try {
      processPayment({
        amount: getOrderTotalPrice(),
        order: orderIdWithTotalOrderMount,
        customer,
      }).then(
        (result: any) => {
          setClientSecret(result.data.clientSecret);
        },
        (error) => {
          if (error.code === 'functions/aborted') {
            setError(<Text tid='soldOutInformation' />);
          } else {
            setError(<Text tid='serverErrorInformation' />);
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  }, []);

  const theme: Appearance['theme'] = 'stripe';

  const appearance = {
    theme: theme,
    variables: {
      colorPrimary: '#0570de',
      colorBackground: '#ffffff',
      colorText: '#30313d',
      colorDanger: '#df1b41',
      fontFamily: 'Ideal Sans, system-ui, sans-serif',
      spacingUnit: '2px',
      borderRadius: '4px',
    },
  };
  const options = {
    clientSecret,
    appearance,
  };

  const toOrderPage = () => {
    reset();
    backToFirst();
  };

  return (
    <>
      {error && (
        <div className='flex flex-col justify-center items-center gap-10'>
          <span className='text-xl font-semibold'>{error}</span>
          <button
            className='bg-brand text-white rounded-lg p-4 h-9 flex items-center justify-center hover:scale-105 duration-100 shadow-md hover:shadow-fontColor'
            onClick={toOrderPage}
          >
            <Text tid='toOrderFirstStep' />
          </button>
        </div>
      )}

      {!error && (
        <div className='flex w-full min-h-80vh justify-center items-center'>
          {clientSecret && elementReady ? (
            <div className='flex flex-wrap w-full justify-between '>
              <div className='md:basis-2/4 max-md:basis-full'>
                <h2 className='text-3xl my-2 bg-gradient-to-r from-brand font-bold text-white p-2 rounded-md w-[100%] mx-auto'>
                  <Text tid='orderSummary' />
                </h2>
                <OrderOverview
                  order={order}
                  totalPrice={getOrderTotalPrice()}
                />
              </div>
              <div className='flex justify-center items-center md:basis-2/4 max-md:basis-full'>
                <Elements options={options} stripe={stripePromise}>
                  <CheckoutForm />
                </Elements>
              </div>
              <button
                onClick={() => handleClick('before')}
                className='flex gap-2 items-center btn-primary transition duration-300 ease-in-out focus:outline-none focus:shadow-outline  hover:bg-brandHover hover:text-white text-brand font-normal py-2 px-4 mr-1 rounded mt-5'
              >
                <FaChevronLeft />
                <Text tid='previous' />
              </button>
            </div>
          ) : (
            <ColorRing
              visible={true}
              height='80'
              width='80'
              ariaLabel='blocks-loading'
              wrapperClass='blocks-wrapper'
              colors={['#8a0917', '#fcdd89', '#f8b26a', '#abbd81', '#8a0917']}
            />
          )}
        </div>
      )}
    </>
  );
};

export default PaymentForm;
