import React, { useState, useEffect } from 'react';
import { FaPencilAlt, FaPlus, FaTrash } from 'react-icons/fa';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useNavigate } from 'react-router-dom';
import AddOrderDayForm from './AddOrderDayForm';
import {
  listenOrderDaysFromToday,
  listenOrderScheduleFromToday,
  removeOrderDay,
} from '../../../api/firebase';
import DateFormatter from './../../../components/DateFormatter';
import {
  SERVER_ERR_MSG,
  deleteModalstyle,
  modalStyle,
} from '../../../common/constant';

const OrderPanel = () => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(undefined);
  const [error, setError] = useState(undefined);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const onDetailBtnClick = (dateId: string) => {
    navigate(`/admin/orderday/${dateId}`);
  };
  const [monthYear, setMonthYear] = useState(
    new Date().toISOString().slice(0, 7)
  );

  const onEditCancel = () => {
    setEditModalOpen(false);
    setSelectedDate(undefined);
  };

  const [orders, setOrders] = useState([]);
  const [orderScehdule, setOrderSchedule] = useState([]);

  useEffect(() => {
    let unsubscribeForOrderDays: () => void;

    listenOrderDaysFromToday(
      (orderDays) => {
        setOrders(orderDays);
      },
      (error) => {
        console.error('Error setting up listener: ', error);
        setError(SERVER_ERR_MSG);
      },
      new Date(monthYear)
    ).then((unsub) => {
      unsubscribeForOrderDays = unsub;
    });

    let unsubscribeForOrderSchedule: () => void;
    listenOrderScheduleFromToday(
      (orderSchedule) => {
        setOrderSchedule(orderSchedule);
      },
      (error) => {
        console.error('Error setting up listener: ', error);
        setError(SERVER_ERR_MSG);
      }
    ).then((unsub) => {
      unsubscribeForOrderSchedule = unsub;
    });

    return () => {
      if (unsubscribeForOrderDays) {
        unsubscribeForOrderDays();
      }
      if (unsubscribeForOrderSchedule) {
        unsubscribeForOrderSchedule();
      }
    };
  }, [monthYear]);

  const getCurrentOrderAmount = (id: string) => {
    return orderScehdule
      ? orderScehdule.find((element) => element.id === id)?.availableAmount
      : 0;
  };

  return (
    <div className='flex flex-col justify-center items-center'>
      {error && <h2 className='text-2xl text-center'>{error}</h2>}
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box sx={modalStyle}>
            <Typography id='modal-modal-title' variant='h6' component='h2'>
              Add New Order Date
            </Typography>
            <AddOrderDayForm handleClose={handleClose} />
          </Box>
        </Modal>
      </div>
      <div>
        <Modal
          open={editModalOpen}
          onClose={() => setEditModalOpen(false)}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box sx={modalStyle}>
            <Typography id='modal-modal-title' variant='h6' component='h2'>
              Edit Order Date - {selectedDate?.date}
            </Typography>
            <AddOrderDayForm
              editDayData={selectedDate}
              handleClose={onEditCancel}
            />
          </Box>
        </Modal>
      </div>
      <div>
        <Modal
          open={deleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box sx={deleteModalstyle}>
            <h2 className='text-2xl text-red-700'>
              Delete order date {selectedDate?.date}
            </h2>
            <p className=''>Do you really want to delete the order date ?</p>
            <div className='flex justify-center gap-5 mt-8'>
              <button
                className='border border-red-700  p-2 rounded-lg'
                onClick={() => {
                  setDeleteModalOpen(false);
                }}
              >
                Cancel
              </button>
              <button
                className='bg-red-700 text-white p-2 rounded-lg'
                onClick={() => {
                  removeOrderDay(selectedDate.id, (err) => {
                    setError(err);
                  });
                  setDeleteModalOpen(false);
                  setSelectedDate(undefined);
                }}
              >
                Delete
              </button>
            </div>
          </Box>
        </Modal>
      </div>
      <div className='w-full flex justify-end my-5 gap-3'>
        <input
          type='month'
          name='start'
          onChange={(e) => {
            setMonthYear(e.target.value);
          }}
          defaultValue={monthYear}
          className='text-lg border-b-2'
        />

        <button
          className='rounded-md bg-brand text-white p-2 flex justify-center items-center gap-2'
          onClick={handleOpen}
        >
          <FaPlus color='white' /> New date
        </button>
      </div>
      <table className='table-auto w-full border-separate border border-slate-400 '>
        <thead>
          <tr>
            <th className='border border-slate-300 '>Order Date</th>
            <th className='border border-slate-300 '>Pick-up Start</th>
            <th className='border border-slate-300 '>Pick-up End</th>
            <th className='border border-slate-300 '>Availablity</th>
            <th className='border border-slate-300 '>Current Orders</th>
            <th className='border border-slate-300 '>Datails</th>
            <th className='border border-slate-300 '>Edit</th>
            <th className='border border-slate-300 '>Delete</th>
          </tr>
        </thead>
        <tbody className='text-center'>
          {orders.map((order) => (
            <tr key={order.id}>
              <td className='border border-slate-300 '>
                {/* @ts-expect-error Server Component */}
                <DateFormatter date={order.date} />
              </td>
              <td className='border border-slate-300 '>{order.startTime}</td>
              <td className='border border-slate-300 '>{order.endTime}</td>
              <td className='border border-slate-300 '>{order.availability}</td>
              {orderScehdule.length > 0 ? (
                <td className='border border-slate-300 '>
                  {+order.availability - +getCurrentOrderAmount(order.id)}
                </td>
              ) : (
                <td className='border border-slate-300 '>0</td>
              )}

              <td className='border border-slate-300 text-center'>
                <button
                  className='rounded-md  p-1 flex justify-center items-center w-full'
                  onClick={() => {
                    onDetailBtnClick(order.id);
                  }}
                >
                  View
                </button>
              </td>
              <td className='border border-slate-300 text-center'>
                <button
                  className='rounded-md  text-white p-1 flex justify-center items-center w-full'
                  onClick={() => {
                    setEditModalOpen(true);
                    setSelectedDate(order);
                  }}
                >
                  <FaPencilAlt color='black' />
                </button>
              </td>
              <td className='border border-slate-300 text-center'>
                <button
                  className='rounded-md  text-white p-1 flex justify-center items-center w-full '
                  onClick={() => {
                    setSelectedDate(order);
                    setDeleteModalOpen(true);
                  }}
                >
                  <FaTrash color='red' />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default OrderPanel;
