export const CHICKEN = 'chicken';
export const DRINK = 'drinks';
export const SIDEMENU = 'sides';
export const SAUCE = 'sauces';
export const LARGESIZE = 400;
export const SMALLSIZE = 200;
export const LARGE = 'large';
export const SMALL = 'small';
export const SAUCEAPARTPRICE = 0.5;

export const MAX_CHICKEN_ORDER = 5;
export const GET_PORT = () =>
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:5000'
    : 'https://chimek.nl';
export const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'fit-content',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};
export const modalStyleForMobile = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export const deleteModalstyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

export const getAmountBySize = (size: string, amount: number) => {
  if (size === LARGE) {
    return amount;
  } else if (size === SMALL) {
    return amount / 2;
  }
  return amount;
};
export const YANGNYEOM = 'Yangnyeom';
export const PADAK = 'Padak';
export const BULDAK = 'Buldak';
export const SUCCEEDED = 'succeeded';
export const FAILD = 'failed';
export const SERVER_ERR_MSG = 'Server error, Please try again.';
