import React from 'react';
import Text from '../../../components/Text';
import DateFormatter from './../../../components/DateFormatter';
import { CHICKEN } from './../../../common/constant';
import { toEuro } from './../../../common/utils';
import { Order } from '../../../types/order';
type OrderOverviewProp = {
  order: Order;
  totalPrice: number;
};

function OrderOverview({ order, totalPrice }: OrderOverviewProp) {
  return (
    <>
      <div className='mb-[1rem] p-2 w-[100%] mx-auto'>
        {order.orderNumber && (
          <h3 className='text-xl font-bold bg-yellow  text-center rounded-md mb-3 p-2'>
            <Text tid='orderNumber' /> - {order.orderNumber}
          </h3>
        )}
        <div className='flex items-center gap-3 '>
          <span className='text-2xl my-2  font-bold  rounded-md underline underline-offset-8 decoration-brand'>
            {/* @ts-expect-error Server Component */}
            <DateFormatter date={order.date} />
          </span>
          <div className='text-sm'>
            <Text tid='pickupAt' />{' '}
            <span className='bg-brand text-white p-1 px-2 rounded-lg font-bold'>
              {order.pickupTime}
            </span>
          </div>
        </div>

        <table className='w-full'>
          <thead>
            <tr className='text-left h-[20px] bg-gray-100 rounded-xl'>
              <th>
                <Text tid='product' />
              </th>
              <th className='w-6'>
                <Text tid='subTotal' />
              </th>
            </tr>
          </thead>
          <>
            {Object.entries(order?.products).map(([product, value], index) => (
              <tbody key={product + index}>
                <tr className='text-xs text-gray-500 '>
                  <td className='p-0 '>
                    <span className='px-2 w-fit bg-yellow rounded-md'>
                      <Text tid={product} />
                    </span>
                  </td>
                </tr>
                {value.map((item: any, index: number) => (
                  <tr key={item.name + index}>
                    <td className='flex gap-5'>
                      {product === CHICKEN ? (
                        <div className='flex flex-col justify-center items-start '>
                          <span>{item.name}</span>
                          <span className='text-sm text-gray-400'>
                            {item.size}
                          </span>
                          <span className='text-sm text-gray-400'>
                            {item.isSauceApart ? (
                              <div className='flex flex-wrap'>
                                <Text tid='sauceApart' />
                              </div>
                            ) : (
                              ''
                            )}
                          </span>
                        </div>
                      ) : (
                        <span>{item.name} </span>
                      )}

                      <span className='font-bold'>x {item.amount}</span>
                    </td>
                    <td>{toEuro(+item.subTotal)}</td>
                  </tr>
                ))}
              </tbody>
            ))}
          </>
        </table>
      </div>

      <hr className='border-t-2 border-gray-500 border-dotted mt-5' />

      <div className='p-2 my-4 flex flex-col items-end'>
        <div className='flex justify-end gap-10'>
          <span className='font-bold  text-xl md:text-2xl '>
            <Text tid='total' />
          </span>
          {totalPrice && (
            <span className='font-bold text-xl px-[1rem] md:text-2xl'>
              {toEuro(+totalPrice)}
            </span>
          )}
        </div>
        <p className='text-xs px-[1rem]'>
          <Text tid='inculdesTax' />
        </p>
      </div>
    </>
  );
}

export default OrderOverview;
