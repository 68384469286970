import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import NotFound from './pages/NotFound';
import Home from './pages/Home';
import Menu from './pages/Menu';
import AboutUs from './pages/AboutUs';
import Order from './pages/order/Order';
import Login from './pages/Login';
import Admin from './pages/admin/Admin';
import ProtectedRoute from './pages/ProtectedRoute';
import OrderPayment from './pages/OrderPayment';
import ContactUs from './pages/ContactUs';
import Policy from './pages/Policy';
import OrderDayDetails from './pages/admin/OrderDayDetails';
import OrderDetails from './pages/admin/OrderDetails';
import { HelmetProvider } from 'react-helmet-async';

const root = ReactDOM.createRoot(document.getElementById('root'));

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <NotFound />,
    children: [
      { index: true, path: '/', element: <Home /> },
      { path: '/menu', element: <Menu /> },
      { path: '/aboutus', element: <AboutUs /> },
      { path: '/order', element: <Order /> },
      { path: '/order/:dateId', element: <Order /> },
      { path: '/login', element: <Login /> },
      { path: '/orderpayment/:slug', element: <OrderPayment /> },
      { path: '/contactus', element: <ContactUs /> },
      { path: '/policy', element: <Policy /> },
      {
        path: '/admin',
        element: (
          <ProtectedRoute requireAdmin>
            <Admin />
          </ProtectedRoute>
        ),
      },
      {
        path: '/admin/orderday/:dateId',
        element: (
          <ProtectedRoute requireAdmin>
            <OrderDayDetails />
          </ProtectedRoute>
        ),
      },
      {
        path: '/admin/bundledetails/:paymentIntent',
        element: (
          <ProtectedRoute requireAdmin>
            <OrderDetails />
          </ProtectedRoute>
        ),
      },
    ],
  },
]);

root.render(
  <HelmetProvider>
    <RouterProvider router={router} />
  </HelmetProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
