import React, { useState } from 'react';
import { addMenu, updateMenu } from '../../../api/firebase';
import Text from '../../../components/Text';
import { Menu } from '../../../types/menu';
import {
  CHICKEN,
  DRINK,
  LARGE,
  LARGESIZE,
  SAUCE,
  SIDEMENU,
  SMALL,
  SMALLSIZE,
} from '../../../common/constant';

type AddMenuFormProp = {
  chickenMenuList: Menu[];
  handleCancel: () => void;
  item?: Menu;
  productName?: string;
};

const AddMenuForm = ({
  chickenMenuList,
  handleCancel,
  item,
  productName,
}: AddMenuFormProp) => {
  const [menu, setMenu] = useState(item ? item : undefined);
  const [product, setProduct] = useState(productName ? productName : undefined);
  const [error, setError] = useState(undefined);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (item) {
      updateMenu(product, menu, (err) => {
        setError(err);
      });
    } else {
      addMenu(product, menu, (err) => {
        setError(err);
      });
    }

    handleCancel();
  };
  const handleChange = (e: any) => {
    const { id, value } = e.target;
    if (id === 'priceL') {
      setMenu((menu) => ({
        ...menu,
        price: { ...menu.price, [LARGE]: value },
      }));
      return;
    }
    if (id === 'priceS') {
      setMenu((menu) => ({
        ...menu,
        price: { ...menu.price, [SMALL]: value },
      }));
      return;
    }
    setMenu((menu) => ({
      ...menu,
      [id]: value,
    }));
  };

  const handleSizeChange = (e: any) => {
    const { id, checked } = e.target;
    const amount = id === LARGE ? LARGESIZE : SMALLSIZE;
    const object = checked && { [id]: amount };
    setMenu((menu) => ({
      ...menu,
      size: { ...menu.size, ...object },
    }));
  };

  const Options = () => (
    <select
      name='product'
      id='product'
      defaultValue={product}
      onChange={(e) => setProduct(e.target.value)}
      required
    >
      <option></option>
      <option value={CHICKEN}>Chicken</option>
      <option value={SIDEMENU}>Side</option>
      <option value={SAUCE}>Sauce</option>
      <option value={DRINK}>Drink</option>
    </select>
  );

  return (
    <>
      {product === CHICKEN && (
        <form
          className='overflow-auto bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col gap-5 max-h-[80vh] max-w-fit'
          onSubmit={handleSubmit}
        >
          {error && <h2 className='text-2xl text-center'>{error}</h2>}
          <div>
            <label
              className='block text-gray-700 text-sm font-bold mb-2'
              htmlFor='product'
            >
              Product
            </label>
            <Options />
          </div>
          <div>
            <label
              className='block text-gray-700 text-sm font-bold mb-2'
              htmlFor='name'
            >
              Name
            </label>
            <input
              className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
              id='name'
              name='name'
              type='text'
              defaultValue={item ? item.name : undefined}
              required
              onChange={handleChange}
            ></input>
          </div>
          <div>
            <label
              className='block text-gray-700 text-sm font-bold mb-2'
              htmlFor='image'
            >
              Image file name
            </label>
            <input
              className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
              id='image'
              name='image'
              type='text'
              defaultValue={item ? item.image : undefined}
              required
              onChange={handleChange}
            ></input>
          </div>
          <div>
            <label
              className='block text-gray-700 text-sm font-bold mb-2'
              htmlFor='description'
            >
              Menu Descriprtion
            </label>
            <textarea
              className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
              id='description'
              name='description'
              defaultValue={item ? item.description : undefined}
              required
              onChange={handleChange}
            ></textarea>
          </div>
          <div>
            <label
              className='block text-gray-700 text-sm font-bold mb-2'
              htmlFor='spicyLevel'
            >
              Spicy level
            </label>
            <select
              name='spicyLevel'
              id='spicyLevel'
              defaultValue={item ? item.spicyLevel : undefined}
              onChange={handleChange}
              required
            >
              <option></option>
              <option value='0'>0</option>
              <option value='1'>1</option>
              <option value='2'>2</option>
              <option value='3'>3</option>
              <option value='4'>4</option>
              <option value='5'>5</option>
            </select>
          </div>
          <div>
            <label
              className='block text-gray-700 text-sm font-bold mb-2'
              htmlFor='size'
            >
              Sizes
            </label>
            <div className='flex gap-2'>
              <input
                type='checkbox'
                id={LARGE}
                onChange={handleSizeChange}
                defaultChecked={!!item?.size?.large}
              />
              <label htmlFor={LARGE}>Large - {LARGESIZE} g</label>
            </div>
            <div className='flex gap-2'>
              <input
                type='checkbox'
                id={SMALL}
                onChange={handleSizeChange}
                defaultChecked={!!item?.size?.small}
              />
              <label htmlFor={SMALL}>Small - {SMALLSIZE} g</label>
            </div>
          </div>
          <div>
            <label
              className='block text-gray-700 text-sm font-bold mb-2'
              htmlFor='priceL'
            >
              Price € - Large
            </label>
            <input
              className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
              id='priceL'
              name='priceL'
              type='number'
              step='0.01'
              required
              defaultValue={item ? item.price?.large : undefined}
              onChange={handleChange}
            />
          </div>
          <div>
            <label
              className='block text-gray-700 text-sm font-bold mb-2'
              htmlFor='priceS'
            >
              Price € - Small
            </label>
            <input
              className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
              id='priceS'
              name='priceS'
              type='number'
              step='0.01'
              required
              defaultValue={item ? item.price?.small : undefined}
              onChange={handleChange}
            />
          </div>
          <div>
            <label
              className='block text-gray-700 text-sm font-bold mb-2'
              htmlFor='badge'
            >
              Badge
            </label>
            <input
              className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
              id='badge'
              name='badge'
              type='text'
              defaultValue={item ? item.badge : undefined}
              onChange={handleChange}
            />
          </div>
          <div className='flex gap-3 justify-center'>
            <button
              type='button'
              onClick={handleCancel}
              className='border border-brand text-brand rounded-lg font-bold flex items-center justify-center gap-3 p-2 w-[30%] min-w-[200px] '
            >
              <Text tid='cancel' />
            </button>

            <button
              className='text-white rounded-lg font-bold flex items-center justify-center gap-3 bg-brand p-2 w-[30%] min-w-[200px] '
              type='submit'
            >
              <Text tid='save' />
            </button>
          </div>
        </form>
      )}
      {product !== CHICKEN && (
        <form
          className='overflow-auto bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col gap-5 max-h-[80vh] max-w-fit'
          onSubmit={handleSubmit}
        >
          {error && <h2 className='text-2xl text-center'>{error}</h2>}
          <div>
            <label
              className='block text-gray-700 text-sm font-bold mb-2'
              htmlFor='product'
            >
              Product
            </label>
            <Options />
          </div>
          <div>
            <label
              className='block text-gray-700 text-sm font-bold mb-2'
              htmlFor='name'
            >
              Name
            </label>
            <input
              className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
              id='name'
              name='name'
              type='text'
              defaultValue={item ? item.name : undefined}
              required
              onChange={handleChange}
            ></input>
          </div>
          {product === SAUCE && (
            <div>
              <label
                className='block text-gray-700 text-sm font-bold mb-2'
                htmlFor='connection'
              >
                Sauce Connection to
              </label>
              <select
                name='connection'
                id='connection'
                defaultValue={item ? item.connection : undefined}
                onChange={handleChange}
                required
              >
                <option></option>
                {chickenMenuList.map((chickenItem) => (
                  <option key={chickenItem.id} value={chickenItem.id}>
                    {chickenItem.name}
                  </option>
                ))}
              </select>
            </div>
          )}
          <div>
            <label
              className='block text-gray-700 text-sm font-bold mb-2'
              htmlFor='image'
            >
              Image file name
            </label>
            <input
              className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
              id='image'
              name='image'
              type='text'
              defaultValue={item ? item.image : undefined}
              required
              onChange={handleChange}
            ></input>
          </div>
          <div>
            <label
              className='block text-gray-700 text-sm font-bold mb-2'
              htmlFor='description'
            >
              Menu Descriprtion
            </label>
            <textarea
              className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
              id='description'
              name='description'
              defaultValue={item ? item.description : undefined}
              onChange={handleChange}
            ></textarea>
          </div>
          <div>
            <label
              className='block text-gray-700 text-sm font-bold mb-2'
              htmlFor='size'
            >
              Size -{product === DRINK ? 'Volume ml' : ' Weight g'}
            </label>
            <input
              className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
              id='size'
              name='size'
              type='number'
              required
              defaultValue={item ? item.size : undefined}
              onChange={handleChange}
            />
          </div>
          <div>
            <label
              className='block text-gray-700 text-sm font-bold mb-2'
              htmlFor='price'
            >
              Price €
            </label>
            <input
              className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
              id='price'
              name='price'
              type='number'
              step='0.01'
              required
              defaultValue={item ? item.price : undefined}
              onChange={handleChange}
            />
          </div>
          {/* <div>
            <label
              className='block text-gray-700 text-sm font-bold mb-2'
              htmlFor='sellable'
            >
              Sellable ?
            </label>
            <select
              name='sellable'
              id='sellable'
              defaultValue={item ? item?.sellable?.toString() : undefined}
              onChange={handleChange}
              required
            >
              <option value='true'>Yes</option>
              <option value='false'>No</option>
            </select>
          </div> */}
          <div className='flex gap-3 justify-center'>
            <button
              type='button'
              onClick={handleCancel}
              className='border border-brand text-brand rounded-lg font-bold flex items-center justify-center gap-3 p-2 w-[30%] min-w-[200px] '
            >
              <Text tid='cancel' />
            </button>

            <button
              className='text-white rounded-lg font-bold flex items-center justify-center gap-3 bg-brand p-2 w-[30%] min-w-[200px] '
              type='submit'
            >
              <Text tid='save' />
            </button>
          </div>
        </form>
      )}
    </>
  );
};

export default AddMenuForm;
