import { useQuery } from "@tanstack/react-query";
import React from "react";
import { ColorRing } from "react-loader-spinner";
import { getAllMenu } from "../api/firebase";
import MenuCard from "../components/MenuCard";
import Text from "../components/Text";
import { CHICKEN, DRINK, SAUCE, SIDEMENU } from "./../common/constant";
import { Helmet } from "react-helmet-async";

const MenuPage = () => {
  const {
    isLoading,
    error,
    data: menuList,
  }: any = useQuery(["allMenu"], () => getAllMenu());

  return (
    <>
      <Helmet>
        <title>Our Menu</title>
        <meta
          name="description"
          content="We bieden een verscheidenheid aan smaken aan, waaronder de klassieke Yangnyeom, Padak en Buldak. En in de toekomst zullen er meer smaken komen."
        />
        <link rel="canonical" href="/menu" />
      </Helmet>
      {isLoading && (
        <div className="w-screen h-screen flex justify-center items-center">
          <ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperClass="blocks-wrapper"
            colors={["#8a0917", "#fcdd89", "#f8b26a", "#abbd81", "#8a0917"]}
          />
        </div>
      )}
      {error && (
        <div className="w-screen h-screen flex justify-center items-center">
          {error}
        </div>
      )}
      {menuList && (
        <div className="mt-[90px]">
          <div className="menuShowcase h-[17vh] flex justify-center items-center md:h-[25vh]">
            <h1 className="text-3xl md:text-5xl font-bold">Menu</h1>
          </div>
          <div className="min-h-screen flex justify-center">
            <div className="w-[65%] min-w-[300px] max-md:w-[95%] border-brand rounded-xl border-double border-[15px] my-10 mx-2 p-5 ">
              <div className="flex flex-col justify-center items-center  my-[2rem] gap-1">
                <h1 className="text-2xl md:text-4xl text-center font-bold">
                  <Text tid="chicken" />
                </h1>
                <span className="font-bold text-brand text-lg md:text-2xl ">
                  100% HALAL
                </span>
              </div>
              <div className="flex flex-col justify-center my-[4rem] gap-[3rem]">
                {menuList[CHICKEN].map((menu: any) => (
                  <div key={menu.id}>
                    <MenuCard menu={menu} category={CHICKEN} unit="g" />
                    <hr className="border-t-2 border-brand border-dotted mt-5" />
                  </div>
                ))}
              </div>
              <h1 className="text-2xl md:text-4xl text-center font-bold">
                <Text tid="sauces" />
              </h1>
              <div className="flex flex-col justify-center my-[4rem] gap-[3rem]">
                {menuList[SAUCE].map((menu: any) => (
                  <div key={menu.id}>
                    <MenuCard menu={menu} category={SAUCE} unit="ml" />
                    <hr className="border-t-2 border-brand border-dotted mt-5" />
                  </div>
                ))}
              </div>
              {/* <h1 className='text-2xl md:text-4xl text-center font-bold'>
                <Text tid='sides' />
              </h1>
              <div className='flex flex-col justify-center my-[4rem] gap-[3rem]'>
                {menuList[SIDEMENU].map((menu: any) => (
                  <div key={menu.id}>
                    <MenuCard menu={menu} category={SIDEMENU} unit='g' />
                    <hr className='border-t-2 border-brand border-dotted mt-5' />
                  </div>
                ))}
              </div>
              <h1 className='text-2xl md:text-4xl text-center font-bold'>
                <Text tid='drinks' />
              </h1>
              <div className='flex flex-col justify-center my-[4rem] gap-[3rem]'>
                {menuList[DRINK].map((menu: any) => (
                  <div key={menu.id}>
                    <MenuCard menu={menu} category={DRINK} unit='ml' />
                    <hr className='border-t-2 border-brand border-dotted mt-5' />
                  </div>
                ))}
              </div> */}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MenuPage;
