import React from 'react';
import Text from '../../components/Text';
import OrderOverview from '../order/components/OrderOverview';
import { ColorRing } from 'react-loader-spinner';
import { useParams } from 'react-router-dom';
import { getOrderByPaymentIntent } from '../../api/firebase';
import { useQuery } from '@tanstack/react-query';

const OrderDetails = () => {
  const { paymentIntent } = useParams();
  const {
    isLoading,
    error: orderError,
    data: orders,
  }: any = useQuery(['ordersByPaymentIntent'], () =>
    getOrderByPaymentIntent(paymentIntent)
  );

  return (
    <div className='mt-[90px] flex justify-center showcase'>
      {orderError && <h2 className='text-2xl text-center'>{orderError}</h2>}

      <div className='flex flex-col  items-center h-fit w-[65%] min-w-[300px] max-md:w-[95%] bg-white border shadow-xl rounded-2xl p-7  my-10'>
        <h2 className='text-3xl my-2 bg-gradient-to-r from-brand font-bold text-white p-2 rounded-md w-[100%] mx-auto'>
          <Text tid='paymentSucceeded' />
        </h2>
        {orders && (
          <div className='mt-10'>
            <h3 className='text-2xl mb-5 text-center'>
              <Text tid='orderConfirmation' />
            </h3>
            <div>
              <div className='flex gap-2'>
                <h4 className='font-bold'>
                  <Text tid='pickupName' /> :{' '}
                </h4>

                {orders[0].customer.firstname +
                  ' ' +
                  orders[0].customer.lastname}
              </div>
              <div className='flex gap-2'>
                <h4 className='font-bold'>
                  <Text tid='pickupLocation' /> :{' '}
                </h4>
                <p>
                  Kooikersplaats 28 <br /> 7328 AX <br /> Apeldoorn
                </p>
              </div>
            </div>

            <div>
              <OrderOverview
                order={orders}
                totalPrice={orders?.length > 0 ? orders[0].totalPaidAmount : 0}
              />
            </div>
            <div>
              <p className='text-md mt-10 mb-5 text-center md:mx-10'>
                <Text tid='orderConfirmationEmailNotice' />
              </p>
            </div>
          </div>
        )}
        {isLoading && (
          <ColorRing
            visible={true}
            height='30'
            width='30'
            ariaLabel='blocks-loading'
            wrapperClass='blocks-wrapper'
            colors={['#8a0917', '#fcdd89', '#f8b26a', '#abbd81', '#8a0917']}
          />
        )}
      </div>
    </div>
  );
};

export default OrderDetails;
