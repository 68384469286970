import React, { createContext, useEffect, useState } from 'react';
import { CHICKEN, LARGE, SERVER_ERR_MSG, SMALL } from '../../common/constant';
import { listenOrderAvailabilityByDateId } from '../../api/firebase';
import Text from '../Text';

const SingleOrderContext = createContext<any>([]);

export const SingleOrderContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [order, setOrder] = useState<any>({});
  const [customer, setCustomer] = useState({});
  const [isOrderInfoModalSeen, setIsOrderInfoModalSeen] = useState(false);
  const [availableAmount, setAvailableAmount] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [cartOpen, setCartOpen] = useState(false);
  const [recentFinalStep, setRecentFinalStep] = useState(1);

  useEffect(() => {
    if (order.products && Object.values(order.products).flat().length === 0) {
      setRecentFinalStep(1);
      setOrder({});
    }
  }, [order]);

  useEffect(() => {
    let unsubscribe: () => void;
    if (order.dateId) {
      listenOrderAvailabilityByDateId(
        order.dateId,
        (result) => {
          if (result === 0) {
            setError(<Text tid='soldOutInformation' />);
          }
          setAvailableAmount(result);
        },
        (error) => {
          console.error('Error setting up listener:', error);
          setError(SERVER_ERR_MSG);
        }
      ).then((unsub) => {
        unsubscribe = unsub;
      });
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [order.dateId]);

  const updateOrder = (order: {}) => {
    setOrder(order);
  };

  const getTotalChickenAmount = () => {
    let total = 0;
    order.products?.hasOwnProperty(CHICKEN) &&
      order.products[CHICKEN].forEach((menu: any) => {
        if (menu.size === LARGE) {
          total += menu.amount;
        }
        if (menu.size === SMALL) {
          total += menu.amount / 2;
        }
      });
    return total;
  };

  const getOrderTotalPrice = () => {
    let total = 0;
    order.products &&
      Object.keys(order.products).forEach((category) => {
        order.products[category].forEach((menu: any) => {
          total += menu.subTotal;
        });
      });
    return total;
  };

  const getAmountByMenuId = (category: string, menuId: string) => {
    if (!order) return 0;
    const menu =
      order.products?.hasOwnProperty(category) &&
      order.products[category].find((menu: any) => menu.id === menuId);

    return menu ? menu.amount : 0;
  };

  const isMenuInOrder = (category: string, menuId: string) => {
    if (!order) return false;
    const menu =
      order.products?.hasOwnProperty(category) &&
      order.products[category].find((menu: any) => menu.id === menuId);

    return menu ? true : false;
  };

  const toggleCartOpen = () => {
    setCartOpen(!cartOpen);
  };

  const getTotalProductAmount = () => {
    let total = 0;
    order.products &&
      Object.keys(order.products).forEach((category) => {
        order.products[category].forEach((menu: any) => {
          total += menu.amount;
        });
      });
    return total;
  };

  const reset = () => {
    setOrder({});
    setCustomer({});
    setError(undefined);
    setRecentFinalStep(1);
  };

  return (
    <SingleOrderContext.Provider
      value={{
        order,
        updateOrder,
        getTotalChickenAmount,
        getOrderTotalPrice,
        customer,
        setCustomer,
        isOrderInfoModalSeen,
        setIsOrderInfoModalSeen,
        error,
        availableAmount,
        getAmountByMenuId,
        isMenuInOrder,
        cartOpen,
        toggleCartOpen,
        setCartOpen,
        getTotalProductAmount,
        recentFinalStep,
        setRecentFinalStep,
        reset,
      }}
    >
      {children}
    </SingleOrderContext.Provider>
  );
};

export const useSingleOrderContext = () => React.useContext(SingleOrderContext);
