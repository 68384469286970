import React, { useState } from 'react';
import { getMenu } from '../../../../api/firebase';
import {
  CHICKEN,
  SERVER_ERR_MSG,
  modalStyle,
} from '../../../../common/constant';
import { useQuery } from '@tanstack/react-query';
import { Menu } from '../../../../types/menu';
import MenuChoice from './MenuChoice';
import { Box, Modal } from '@mui/material';
import SingleMenuOptions from '../SingleMenuOptions';
import { ColorRing } from 'react-loader-spinner';
import { useSingleOrderContext } from '../../../../components/context/SingleOrderContext';
import Text from '../../../../components/Text';

const ChickenTabPanel = () => {
  const {
    isLoading,
    error: menuError,
    data: menuList,
  }: any = useQuery(['menu'], () => getMenu(CHICKEN));
  const [focusedMenu, setFocusedMenu] = useState(undefined);
  const [singleChickenModalOpen, setSingleChickenModalOpen] = useState(false);
  const { order, updateOrder, availableAmount, getTotalChickenAmount } =
    useSingleOrderContext();

  const openSingleChickenModal = () => {
    setSingleChickenModalOpen(true);
  };

  const onAddOrder = (product: {
    id: string;
    name: string;
    price: number;
    size: string;
    amount: number;
    isSauceApart: boolean;
  }) => {
    const updatedPart = order.products?.hasOwnProperty(CHICKEN)
      ? {
          ...order,
          products: {
            ...order.products,
            [CHICKEN]: [...order.products[CHICKEN], product],
          },
        }
      : {
          ...order,
          products: {
            ...order.products,
            [CHICKEN]: [product],
          },
        };

    updateOrder(updatedPart);
  };

  return (
    <div className='flex flex-wrap gap-10 justify-center items-center mt-5 max-sm:flex-col max-sm:gap-[70px]'>
      <Modal
        open={singleChickenModalOpen}
        onClose={() => {
          setSingleChickenModalOpen(false);
        }}
      >
        <Box
          sx={{
            ...modalStyle,
            height: '95%',
            width: '90%',
            overflow: 'scroll',
          }}
        >
          <SingleMenuOptions
            menu={focusedMenu}
            closeModal={() => setSingleChickenModalOpen(false)}
            availableAmount={availableAmount}
            onAddOrder={onAddOrder}
            currentTotalChickenAmount={getTotalChickenAmount()}
          />
        </Box>
      </Modal>
      {isLoading && (
        <ColorRing
          visible={true}
          height='80'
          width='80'
          ariaLabel='blocks-loading'
          wrapperClass='blocks-wrapper'
          colors={['#8a0917', '#fcdd89', '#f8b26a', '#abbd81', '#8a0917']}
        />
      )}
      {menuList?.length === 0 && (
        <div className='h-[100px] flex items-center  justify-center md:h-[200px]'>
          <h2 className='text-sm text-center justify-center text-gray-500 md:text-lg'>
            <Text tid='noProduct' />
          </h2>
        </div>
      )}
      {menuList &&
        menuList.map((menu: Menu) => (
          <MenuChoice
            category={CHICKEN}
            menu={menu}
            key={menu.id}
            onButtonClick={() => {
              setFocusedMenu(menu);
              openSingleChickenModal();
            }}
          />
        ))}
      {menuError && <h2 className='text-2xl text-center'>{SERVER_ERR_MSG}</h2>}
    </div>
  );
};

export default ChickenTabPanel;
