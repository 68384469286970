import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import OrderPanel from "./components/OrderPanel";
import MenuPanel from "./components/MenuPanel";
import { listenSiteMode, updateSiteMode } from "../../api/firebase";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const Admin = () => {
  const [value, setValue] = useState(0);
  const [orderMode, setOrderMode] = useState<boolean>(undefined);
  const [modeError, setModeError] = useState(undefined);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  useEffect(() => {
    let unsubscribeForOrderMode: () => void;

    listenSiteMode(
      (orderMode) => {
        setOrderMode(orderMode);
      },
      (error) => {
        setModeError(error);
      }
    ).then((unsub) => {
      unsubscribeForOrderMode = unsub;
    });
  }, []);

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  function onModeChange(value: string) {
    const booleanValue = value === "true" ? true : false;
    setOrderMode(booleanValue);
    updateSiteMode(booleanValue, (error) => {
      setModeError(error);
    });
  }

  return (
    <div className="mt-[90px] flex justify-center">
      <div className="my-10 w-[80%] min-w-[300px] max-md:w-[95%] ">
        <div>
          <div className="relative p-2">
            <div className="flex flex-row gap-3">
              <span>Is Order Mode?</span>
              <select
                name="isOrderMode"
                id="isOrderMode"
                value={orderMode ? "true" : "false"}
                onChange={(e) => onModeChange(e.target.value)}
                className={`text-sm ${
                  orderMode
                    ? "bg-green-600 rounded-md text-white"
                    : "bg-gray-300 rounded-md "
                }`}
              >
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
            </div>
          </div>
        </div>
        <Box sx={{ width: "100%", padding: "0px" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Order" {...a11yProps(0)} />
              <Tab label="Menu" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <OrderPanel />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <MenuPanel />
          </TabPanel>
        </Box>
      </div>
    </div>
  );
};

export default Admin;
