import React, { useEffect } from 'react';
import { Menu } from '../../../types/menu';
import Text from '../../../components/Text';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { AiFillCloseCircle } from 'react-icons/ai';
import { titleCase, toEuro } from '../../../common/utils';
import { BsCartPlus } from 'react-icons/bs';
import {
  MAX_CHICKEN_ORDER,
  SAUCEAPARTPRICE,
  SMALL,
} from '../../../common/constant';

type SingleMenuOptionsProps = {
  menu: Menu;
  closeModal: () => void;
  availableAmount: number;
  onAddOrder: (product: any) => void;
  currentTotalChickenAmount: number;
};

const SingleMenuOptions = ({
  menu,
  closeModal,
  availableAmount,
  onAddOrder,
  currentTotalChickenAmount,
}: SingleMenuOptionsProps) => {
  const [selectedSize, setSelectedSize] = React.useState(undefined);
  const [isSauceApart, setIsSauceApart] = React.useState(false);
  const [amount, setAmount] = React.useState(0);
  const [showSizeRequired, setShowSizeRequired] = React.useState(false);
  const [showAmountRequired, setShowAmountRequired] = React.useState(false);
  const [disableButton, setDisableButton] = React.useState(false);
  const [moreThanMax, setMoreThanMax] = React.useState(false);
  const [moreThanLimit, setMoreThanLimit] = React.useState(false);

  useEffect(() => {
    if (amount > 0) {
      setShowAmountRequired(false);
    }
  }, [amount]);

  useEffect(() => {
    if (selectedSize !== undefined) {
      setShowSizeRequired(false);
    }
    setAmount(0);
  }, [selectedSize]);

  useEffect(() => {
    const isMoreThanMax =
      selectedSize === SMALL
        ? currentTotalChickenAmount + amount / 2 >= MAX_CHICKEN_ORDER
        : currentTotalChickenAmount + amount >= MAX_CHICKEN_ORDER;
    const isMoreThanLimit =
      selectedSize === SMALL
        ? currentTotalChickenAmount + amount / 2 >= availableAmount
        : currentTotalChickenAmount + amount >= availableAmount;

    isMoreThanMax ? setMoreThanMax(true) : setMoreThanMax(false);
    isMoreThanLimit ? setMoreThanLimit(true) : setMoreThanLimit(false);

    isMoreThanMax || isMoreThanLimit
      ? setDisableButton(true)
      : setDisableButton(false);
  }, [currentTotalChickenAmount, availableAmount, amount]);

  const onSizeChange = (key: string) => {
    setSelectedSize(key);
  };

  const calculateTotalPrice = () => {
    if (isSauceApart) {
      return +menu.price[selectedSize] * amount + SAUCEAPARTPRICE * amount;
    }
    return +menu.price[selectedSize] * amount;
  };
  const showSizeRequiredMessage = () => {
    if (selectedSize === undefined) {
      setShowSizeRequired(true);
    }
  };

  const onAmountAdd = () => {
    if (selectedSize === undefined) return;
    setAmount(amount + 1);
  };

  const onAddBtnClick = () => {
    if (selectedSize === undefined) {
      setShowSizeRequired(true);
      return;
    }

    if (amount === 0) {
      setShowAmountRequired(true);
      return;
    }

    onAddOrder({
      amount: amount,
      id: menu.id,
      name: menu.name,
      price: +menu.price[selectedSize],
      subTotal: calculateTotalPrice(),
      size: selectedSize,
      isSauceApart,
      image: menu.image,
    });
    closeModal();
  };

  return (
    <>
      <div className='absolute top-3 right-3' onClick={closeModal}>
        <AiFillCloseCircle size={30} color='grey' />
      </div>
      {availableAmount > 0 ? (
        <div className='flex flex-wrap justify-evenly mt-5'>
          <div className='basis-full md:basis-1/2 flex justify-center'>
            <img
              src={`/${menu.image}`}
              alt={menu.image}
              className=' w-[80%] h-[auto] object-cover rounded-xl shadow-md mb-10'
            />
          </div>
          <div className='basis-full md:basis-1/2 flex justify-center '>
            <div className='w-full md:w-[70%]'>
              <h1 className='text-2xl font-bold md:text-3xl'>{menu.name}</h1>
              <p>
                <Text tid={menu.description} />
              </p>
              <div className='mt-10'>
                <div className='mt-5  max-w-[100%] '>
                  <h3 className=' bg-white text-brand font-bold p-1'>
                    <Text tid='size' />
                  </h3>
                  <ul className='border-4 border-double border-brand rounded-lg p-3'>
                    {Object.entries(menu.price).map(([key, value]) => (
                      <li
                        key={key}
                        className='flex  items-center gap-2'
                        onClick={() => onSizeChange(key)}
                      >
                        <input
                          type='checkbox'
                          id={key}
                          checked={selectedSize === key}
                          onChange={() => onSizeChange(key)}
                        ></input>
                        <span>{titleCase(key)}</span>
                        <span>{`${toEuro(+value)}`}</span>
                      </li>
                    ))}
                  </ul>
                </div>
                {showSizeRequired && (
                  <span className='text-red-500'>
                    <Text tid='sizeRequired' />
                  </span>
                )}
                <div className='mt-5  max-w-[100%] '>
                  <h3 className=' bg-white text-brand font-bold p-1'>
                    <Text tid='sauceOption' />
                  </h3>
                  <ul className='border-4 border-double border-brand rounded-lg p-3'>
                    <li className='flex gap-2 items-center'>
                      <input
                        type='checkbox'
                        onChange={(e) => {
                          setIsSauceApart(e.target.checked);
                        }}
                      ></input>
                      <Text tid='sauceApart' />
                      <div className='flex flex-col'>
                        <span>{`${toEuro(SAUCEAPARTPRICE)}`}</span>
                        <span className='text-xs'>
                          {`incl. ${toEuro(0.05)} `}
                          <Text tid='plasticTax' />
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='mb-10'>
                <div
                  className={`mt-20 mb-2 flex items-center gap-3 justify-between`}
                >
                  <div
                    className='flex items-center gap-3 '
                    onClick={showSizeRequiredMessage}
                  >
                    <button
                      type='button'
                      disabled={selectedSize === undefined}
                      onClick={() => setAmount(amount - 1 > 0 ? amount - 1 : 0)}
                      className={`rounded-[50%] text-white bg-brand w-8 h-8 shadow-md shadow-gray-500 flex justify-center items-center ${
                        selectedSize === undefined && 'bg-gray-400 text-white'
                      }`}
                    >
                      <FaMinus />
                    </button>
                    <span className='w-5 text-center text-xl'>{amount}</span>
                    <button
                      type='button'
                      disabled={disableButton || selectedSize === undefined}
                      onClick={onAmountAdd}
                      className={`rounded-[50%] text-white bg-brand w-8 h-8 shadow-md shadow-gray-400 flex justify-center items-center first-letter first-letter ${
                        (disableButton || selectedSize === undefined) &&
                        'bg-gray-400 text-white'
                      } `}
                    >
                      <FaPlus />
                    </button>
                  </div>

                  <button
                    type='button'
                    onClick={onAddBtnClick}
                    className='min-w-[60%] bg-yellow text-black rounded-lg p-4 h-9 flex items-center justify-center gap-1 hover:scale-105 duration-100 shadow-md hover:shadow-fontColor'
                  >
                    <BsCartPlus size={20} />
                    <Text tid='add' />
                  </button>
                </div>
                {showAmountRequired && (
                  <span className='mt-2 text-red-500'>
                    <Text tid='amountRequired' />
                  </span>
                )}
              </div>
            </div>
          </div>
          {moreThanLimit ||
            (moreThanMax && (
              <div className='flex flex-col justify-center  bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative w-fit mx-auto mt-[20px]'>
                {moreThanMax && (
                  <div className='flex flex-col gap-3 mb-5'>
                    <h3 className='text-lg font-bold'>
                      <Text tid='notice' />
                    </h3>
                    <div className='flex flex-col gap-2'>
                      <p>
                        <Text tid='maxFive' />
                      </p>
                      <p>
                        <Text tid='contactForMoreThanFive' />
                      </p>
                    </div>
                  </div>
                )}
                {moreThanLimit && (
                  <div className='flex flex-col gap-3 mb-5'>
                    <h3 className='text-lg font-bold'>
                      <Text tid='notice' />
                    </h3>
                    <div className='flex gap-1'>
                      <p>
                        <Text tid='currently' />
                      </p>
                      <span className='font-bold'> {availableAmount} </span>
                      <p>
                        <Text tid='currentLimitReached' />
                      </p>
                    </div>
                  </div>
                )}
              </div>
            ))}
        </div>
      ) : (
        <div>
          <h2 className='text-2xl text-center'>
            <Text tid='soldOut' />
          </h2>
          <button
            type='button'
            className='bg-brand text-white rounded-lg p-4 h-9 flex items-center justify-center hover:scale-105 duration-100 shadow-md hover:shadow-fontColor'
            onClick={closeModal}
          >
            <Text tid='toOrderFirstStep' />
          </button>
        </div>
      )}
    </>
  );
};

export default SingleMenuOptions;
