import React from 'react';
import { FaEnvelope, FaWhatsapp } from 'react-icons/fa';
import Text from './Text';

const PickUpInfo = () => {
  return (
    <>
      <div className='flex flex-col items-start justify-between w-full min-h-[55%] md:flex-row mt-5'>
        <div className='flex flex-col justify-between w-full '>
          <h2 className='text-4xl font-bold text-brand mb-16 md:text-5xl max-md:mt-[2rem] max-md:mb-4'>
            <Text tid='pickUpLocation' />
          </h2>
          <p className='mb-10 text-xl md:text-2xl '>
            Kooikersplaats 28 <br /> 7328 AX <br /> Apeldoorn
          </p>
          <div className='flex flex-col gap-1'>
            <a
              className='flex gap-2'
              href='https://mail.google.com/mail/?view=cm&fs=1&to=chimeknl@gmail.com'
            >
              <FaEnvelope size={'1.3rem'} /> chimeknl@gmail.com
            </a>
            <a
              href='https://api.whatsapp.com/send?phone=31641685401'
              className='flex gap-2'
            >
              <FaWhatsapp size={'1.3rem'} /> 0641685401
            </a>
          </div>
        </div>
        <div className='h-3/4 w-full my-5'>
          <iframe
            width='100%'
            height='100%'
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2445.5581236515195!2d5.982748815944215!3d52.196904267875176!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c7b8bd7d8d2d43%3A0xd53239233bd53179!2sKooikersplaats%2028%2C%207328%20AX%20Apeldoorn!5e0!3m2!1sen!2snl!4v1674408535013!5m2!1sen!2snl'
          ></iframe>
        </div>
      </div>
      <div>
        <p>
          <Text tid='pickupFirstSentence' />{' '}
          <strong>
            <Text tid='pickupSecondSentence' />
          </strong>
          .
        </p>
        <p>
          <Text tid='pickupThirdSentence' />
        </p>
        <p>
          <Text tid='pickupForthSentence' />
        </p>
      </div>
    </>
  );
};

export default PickUpInfo;
