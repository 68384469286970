import React from 'react';
import { useSingleOrderContext } from '../context/SingleOrderContext';
import Text from '../Text';
import { CHICKEN, DRINK, SAUCE, SIDEMENU } from '../../common/constant';
import CartItem from './CartItem';
import { toEuro } from '../../common/utils';
import { useNavigate } from 'react-router-dom';

const Cart = () => {
  const navigate = useNavigate();
  const {
    order,
    getOrderTotalPrice,
    setCartOpen,
    recentFinalStep,
    setRecentFinalStep,
    getTotalProductAmount,
  } = useSingleOrderContext();

  const onOrderButtonClick = () => {
    if (order.products === undefined) return;

    if (recentFinalStep === 2 && getTotalProductAmount() > 0) {
      setRecentFinalStep(3);
    }
    navigate(`/order/${order.dateId}`);
    setCartOpen(false);
  };
  return (
    <div className='flex flex-col justify-start items-center min-h-[250px] my-5 gap-4 '>
      {(order.products === undefined ||
        Object.values(order.products).flat().length === 0) && (
        <h4 className='text-lg text-center text-gray-500 my-10'>
          <Text tid='emptyCart' />
        </h4>
      )}
      {order.products?.hasOwnProperty(CHICKEN) &&
        order.products[CHICKEN].map((product: any, index: number) => (
          <CartItem
            item={product}
            key={product.id + index}
            category={CHICKEN}
          />
        ))}
      {order.products?.hasOwnProperty(SIDEMENU) &&
        order.products[SIDEMENU].map((product: any) => (
          <CartItem item={product} key={product.id} category={SIDEMENU} />
        ))}
      {order.products?.hasOwnProperty(SAUCE) &&
        order.products[SAUCE].map((product: any) => (
          <CartItem item={product} key={product.id} category={SAUCE} />
        ))}
      {order.products?.hasOwnProperty(DRINK) &&
        order.products[DRINK].map((product: any) => (
          <CartItem item={product} key={product.id} category={DRINK} />
        ))}
      {order.products !== undefined &&
        Object.values(order.products).flat().length > 0 && (
          <>
            <h4 className='text-lg text-right w-full my-5'>
              <Text tid='total' /> {toEuro(getOrderTotalPrice())}
            </h4>
            <div className='mb-10 flex justify-center items-center w-full'>
              <button
                onClick={onOrderButtonClick}
                className='w-[90%] bg-brand text-white rounded-lg p-4 h-9 flex items-center justify-center hover:scale-105 duration-100 shadow-md hover:shadow-fontColor'
              >
                <Text tid='orderButton' />
              </button>
            </div>
          </>
        )}
    </div>
  );
};

export default Cart;
