import moment from 'moment';
import React, { useState } from 'react';
import { addOrderDay, updateOrderDay } from '../../../api/firebase';
import Text from '../../../components/Text';

interface AddOrderDayFormProps {
  handleClose: () => void;
  editDayData?: any;
}

const AddOrderDayForm = ({
  handleClose,
  editDayData,
}: AddOrderDayFormProps) => {
  const [startTime, setStartTime] = useState(
    editDayData ? editDayData.startTime : '16:00'
  );
  const [endTime, setEndTime] = useState(
    editDayData ? editDayData.endTime : '18:00'
  );
  const [date, setDate] = useState(editDayData ? editDayData.date : undefined);
  const [availability, setAvailability] = useState(
    editDayData ? editDayData.availability : undefined
  );
  const [error, setError] = useState(undefined);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const start = moment(startTime, 'HH:mm');
    const end = moment(endTime, 'HH:mm');
    if (date) {
      updateOrderDay({ date, startTime, endTime, availability }, (err) => {
        setError(err);
      });
    }
    if (start < end) {
      addOrderDay({ date, startTime, endTime, availability }, (err) => {
        setError(err);
      });
    }
    handleClose();
  };

  return (
    <form
      className='bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col gap-5 '
      onSubmit={handleSubmit}
    >
      {error && <h2 className='text-2xl text-center'>{error}</h2>}
      <div>
        <label
          className='block text-gray-700 text-sm font-bold mb-2'
          htmlFor='date'
        >
          Date
        </label>
        <input
          className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
          id='date'
          name='date'
          type='date'
          value={date}
          required
          onChange={(e) => setDate(e.target.value)}
        ></input>
      </div>
      <div>
        <label
          className='block text-gray-700 text-sm font-bold mb-2'
          htmlFor='availability'
        >
          Availability
        </label>
        <input
          className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
          id='availability'
          name='availability'
          type='number'
          value={availability}
          required
          onChange={(e) => setAvailability(e.target.value)}
        ></input>
      </div>
      <div>
        <label
          className='block text-gray-700 text-sm font-bold mb-2'
          htmlFor='startTime'
        >
          Pick start time
        </label>
        <input
          className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
          id='startTime'
          name='startTime'
          type='time'
          required
          onChange={(e) => setStartTime(e.target.value)}
          value={startTime}
          step='3600'
        ></input>
      </div>
      <div>
        <label
          className='block text-gray-700 text-sm font-bold mb-2'
          htmlFor='endTime'
        >
          Pick end time
        </label>
        <input
          className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
          id='endTime'
          name='endTime'
          type='time'
          required
          value={endTime}
          onChange={(e) => setEndTime(e.target.value)}
          step='3600'
        ></input>
      </div>

      <div className='flex justify-center gap-5'>
        <button
          className='text-brand rounded-lg font-bold p-2 w-[30%] min-w-[200px]  border border-brand'
          onClick={handleClose}
        >
          <Text tid='cancel' />
        </button>
        <button
          className='text-white rounded-lg font-bold  bg-brand p-2 w-[30%] min-w-[200px] '
          type='submit'
        >
          <Text tid='Save' />
        </button>
      </div>
    </form>
  );
};

export default AddOrderDayForm;
