import React, { useState, useEffect } from 'react';
import { FaPencilAlt, FaPlus, FaTrash } from 'react-icons/fa';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import AddMenuForm from './AddMenuForm';
import { listenMenu, removeMenu } from '../../../api/firebase';
import {
  SERVER_ERR_MSG,
  deleteModalstyle,
  modalStyle,
} from '../../../common/constant';
import { toEuro } from './../../../common/utils';
import { CHICKEN } from './../../../common/constant';
import { MenuList } from '../../../types/menuCategory';

const MenuPanel = () => {
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [menuList, setMenuList] = useState<MenuList>(undefined);
  const [selectedMenu, setSelectedMenu] = useState(undefined);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [product, setProduct] = useState(undefined);
  const [error, setError] = useState(undefined);

  useEffect(() => {
    let unsubscribe: () => void;
    listenMenu(
      (menuList) => {
        setMenuList(menuList);
      },
      (error) => {
        console.error('Error setting up listener:', error);
        setError(SERVER_ERR_MSG);
      }
    ).then((unsub) => {
      unsubscribe = unsub;
    });
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  return (
    <div>
      {error && <h2 className='text-2xl text-center'>{error}</h2>}
      <div>
        <Modal
          open={addModalOpen}
          onClose={() => {
            setAddModalOpen(false);
            setSelectedMenu(undefined);
            setEditModalOpen(false);
          }}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box sx={modalStyle}>
            {editModalOpen ? (
              <Typography id='modal-modal-title' variant='h6' component='h2'>
                Edit Menu - {selectedMenu.name}
              </Typography>
            ) : (
              <Typography id='modal-modal-title' variant='h6' component='h2'>
                Add New Menu
              </Typography>
            )}

            <AddMenuForm
              chickenMenuList={
                menuList && menuList[CHICKEN]
                  ? Object.values(menuList[CHICKEN])
                  : []
              }
              productName={product}
              item={selectedMenu}
              handleCancel={() => {
                setAddModalOpen(false);
                setSelectedMenu(undefined);
                setEditModalOpen(false);
              }}
            />
          </Box>
        </Modal>
      </div>
      <div>
        <Modal
          open={deleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box sx={deleteModalstyle}>
            <h2 className='text-2xl text-red-700'>
              Delete Menu {selectedMenu?.name}
            </h2>
            <p className=''>Do you really want to delete the menu?</p>
            <div className='flex justify-center gap-5 mt-8'>
              <button
                className='border border-red-700  p-2 rounded-lg'
                onClick={() => {
                  setDeleteModalOpen(false);
                }}
              >
                Cancel
              </button>
              <button
                className='bg-red-700 text-white p-2 rounded-lg'
                onClick={() => {
                  removeMenu(product, selectedMenu.id, (err) => setError(err));
                  setDeleteModalOpen(false);
                  setSelectedMenu(undefined);
                }}
              >
                Delete
              </button>
            </div>
          </Box>
        </Modal>
      </div>
      <div className='w-full flex justify-end my-5 gap-3'>
        <button
          className='rounded-md bg-brand text-white p-2 flex justify-center items-center gap-2'
          onClick={() => {
            setAddModalOpen(true);
          }}
        >
          <FaPlus color='white' /> New menu
        </button>
      </div>
      {menuList &&
        Object.entries(menuList).map(([key, value]) => (
          <div key={key}>
            <h1 className='text-2xl text-center m-5 font-bold '>{key}</h1>
            {key === CHICKEN ? (
              <table className='table-auto w-full border-separate border border-slate-400 '>
                <thead>
                  <tr>
                    <th className='border border-slate-300 '>Name</th>
                    <th className='border border-slate-300 '>Price</th>
                    <th className='border border-slate-300 '>spicy Level</th>
                    <th className='border border-slate-300 '>Size</th>
                    <th className='border border-slate-300 '>badge</th>
                    <th className='border border-slate-300 '>Edit</th>
                    <th className='border border-slate-300 '>Delete</th>
                  </tr>
                </thead>
                <tbody className='text-center'>
                  {Object.values(value).map((item) => (
                    <tr key={item.id}>
                      <td className='border border-slate-300 '>{item.name}</td>
                      <td className='border border-slate-300 '>
                        {Object.values(item.price)
                          .map((price) => toEuro(+price))
                          .join('/ ')}
                      </td>
                      <td className='border border-slate-300 '>
                        {item.spicyLevel}
                      </td>
                      <td className='border border-slate-300 '>
                        {Object.keys(item.size).join('/ ')}
                      </td>
                      <td className='border border-slate-300 '>{item.badge}</td>

                      <td className='border border-slate-300 text-center'>
                        <button
                          className='rounded-md  text-white p-1 flex justify-center items-center w-full'
                          onClick={() => {
                            setProduct(key);
                            setAddModalOpen(true);
                            setSelectedMenu(item);
                            setEditModalOpen(true);
                          }}
                        >
                          <FaPencilAlt color='black' />
                        </button>
                      </td>
                      <td className='border border-slate-300 text-center'>
                        <button
                          className='rounded-md  text-white p-1 flex justify-center items-center w-full '
                          onClick={() => {
                            setProduct(key);
                            setSelectedMenu(item);
                            setDeleteModalOpen(true);
                          }}
                        >
                          <FaTrash color='red' />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <table className='table-auto w-full border-separate border border-slate-400 '>
                <thead>
                  <tr>
                    <th className='border border-slate-300 '>Name</th>
                    <th className='border border-slate-300 '>Price</th>
                    <th className='border border-slate-300 '>Size</th>
                    <th className='border border-slate-300 '>Edit</th>
                    <th className='border border-slate-300 '>Delete</th>
                  </tr>
                </thead>
                <tbody className='text-center'>
                  {Object.values(value).map((item) => (
                    <tr key={item.id}>
                      <td className='border border-slate-300 '>{item.name}</td>
                      <td className='border border-slate-300 '>
                        {toEuro(+item.price)}
                      </td>
                      <td className='border border-slate-300 '>{item.size}</td>
                      <td className='border border-slate-300 text-center'>
                        <button
                          className='rounded-md  text-white p-1 flex justify-center items-center w-full'
                          onClick={() => {
                            setProduct(key);
                            setAddModalOpen(true);
                            setSelectedMenu(item);
                            setEditModalOpen(true);
                          }}
                        >
                          <FaPencilAlt color='black' />
                        </button>
                      </td>
                      <td className='border border-slate-300 text-center'>
                        <button
                          className='rounded-md  text-white p-1 flex justify-center items-center w-full '
                          onClick={() => {
                            setProduct(key);
                            setSelectedMenu(item);
                            setDeleteModalOpen(true);
                          }}
                        >
                          <FaTrash color='red' />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        ))}
    </div>
  );
};

export default MenuPanel;
