// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";
import {
  getDatabase,
  ref,
  set,
  get,
  orderByChild,
  query,
  startAt,
  remove,
  onValue,
  update,
  runTransaction,
  equalTo,
  off,
  endAt,
} from "firebase/database";
import moment from "moment";
import { v4 as uuid } from "uuid";
import { toTimeStamp } from "../common/utils";
import { Menu } from "../types/menu";
//import { getAnalytics } from "firebase/analytics";
import {
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator,
} from "firebase/functions";
import { MenuList } from "../types/menuCategory";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIL,
  databaseURL: process.env.REACT_APP_FIREBASE_DB_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);

const provider = new GoogleAuthProvider();
const auth = getAuth();
const database = getDatabase(app);
const functions = getFunctions(app);

// comment out when deploying
// if (process.env.NODE_ENV === 'development') {
//   connectFunctionsEmulator(functions, 'localhost', 5001);
// }

export async function login() {
  return signInWithPopup(auth, provider)
    .then((result) => {
      // The signed-in user info.
      const user = result.user;
      return user;
    })
    .then(async (user) => {
      return await adminUser(user);
    })
    .catch((error) => {
      console.error(error);
    });
}

export async function logout() {
  return signOut(auth).catch(console.error);
}

export function onUserStateChange(callback: any) {
  onAuthStateChanged(auth, async (user) => {
    const updatedUser = user ? await adminUser(user) : null;
    callback(updatedUser);
  });
}

export async function listenOrderDaysFromToday(
  callback: (orderDays: any[]) => void,
  errorCallback: (error: Error) => void,
  orderMonthYear: Date
) {
  const y = orderMonthYear.getFullYear(),
    m = orderMonthYear.getMonth();
  var firstDay = new Date(y, m, 1);
  var lastDay = new Date(y, m + 1, 0);

  const startingOrderDate = new Date(orderMonthYear).setDate(
    firstDay.getDate()
  );
  const endOrderDate = new Date(orderMonthYear).setDate(lastDay.getDate());

  const queryRef = query(
    ref(database, "orderDays"),
    orderByChild("id"),
    startAt(startingOrderDate),
    endAt(endOrderDate)
  );
  const listener = onValue(
    queryRef,
    (snapshot) => {
      if (snapshot.exists()) {
        const orderDays = Object.values(snapshot.val());
        callback(orderDays);
      } else {
        callback([]);
      }
    },
    errorCallback
  );
  const unsubscribe = () => {
    off(queryRef, "value", listener);
  };
  return unsubscribe;
}

export async function listenOrderScheduleFromToday(
  callback: (orderDays: any[]) => void,
  errorCallback: (error: Error) => void
) {
  const today = new Date();
  const availableStartingOrderDate = new Date().setDate(today.getDate());
  const queryRef = query(
    ref(database, "orderSchedule"),
    orderByChild("id"),
    startAt(availableStartingOrderDate)
  );
  const listener = onValue(
    queryRef,
    (snapshot) => {
      if (snapshot.exists()) {
        const orderDays = Object.values(snapshot.val());
        callback(orderDays);
      }
    },
    errorCallback
  );
  const unsubscribe = () => {
    off(queryRef, "value", listener);
  };
  return unsubscribe;
}

export async function getOrderDaysFromToday() {
  const today = new Date();
  const availableStartingOrderDate = new Date().setDate(today.getDate() + 1);
  return get(
    query(
      ref(database, "orderDays"),
      orderByChild("id"),
      startAt(availableStartingOrderDate)
    )
  ).then((snapshot) => {
    if (snapshot.exists()) {
      return Object.values(snapshot.val());
    }
    return [];
  });
}
async function adminUser(user: any) {
  return get(ref(database, "admins")).then((snapshot) => {
    if (snapshot.exists()) {
      const admins = snapshot.val();
      const isAdmin = !!admins[user.uid];
      return { ...user, isAdmin };
    }
    return user;
  });
}

export async function addOrderDay(
  orderDay: any,
  errorCallback: (error: Error) => void
) {
  const timestamp = toTimeStamp(orderDay.date);
  const data = { ...orderDay, id: timestamp };
  try {
    await set(ref(database, `orderDays/${timestamp}`), data);
  } catch (error) {
    console.error("Error adding order day:", error);
    errorCallback(error);
  }

  try {
    await addOrderSchedule(
      timestamp,
      orderDay.startTime,
      orderDay.endTime,
      orderDay.availability
    );
  } catch (error) {
    console.error("Error adding order schedule:", error);
    await remove(ref(database, `orderDays/${timestamp}`));
    errorCallback(error);
  }
}

export async function updateOrderDay(
  orderDay: any,
  errorCallback: (error: Error) => void
) {
  try {
    // update orderDay
    const timestamp = toTimeStamp(orderDay.date);
    const data = { ...orderDay, id: timestamp };
    update(ref(database, `orderDays/${timestamp}`), {
      ...data,
    });

    // update orderSchedule
    const updatedTimeSlot = generateTimeSlots(
      orderDay.startTime,
      orderDay.endTime
    ).reduce((o, key) => ({ ...o, [key]: [true] }), {});

    const scheduleRef = ref(database, `orderSchedule/${timestamp}`);
    runTransaction(scheduleRef, (schedule) => {
      if (schedule) {
        if (schedule.timeslots) {
          const prevTimeSlot = schedule.timeslots;
          const timeslots = mergeObjects(prevTimeSlot, updatedTimeSlot);
          const updatedAvailability =
            +orderDay.availability - countArrayValues(timeslots);
          schedule.timeslots = timeslots;
          schedule.availableAmount = updatedAvailability;
        }
      }
      return schedule;
    });
  } catch (error) {
    console.error(`Error updating order day: ${error.message}`);
    errorCallback(error);
  }
}

export async function listenSiteMode(
  callback: (orderMode: any) => void,
  errorCallback: (error: Error) => void
) {
  const queryRef = query(ref(database, "mode"));
  const listener = onValue(
    queryRef,
    (snapshot) => {
      if (snapshot.exists()) {
        const orderMode = Object.values(snapshot.val())[0];
        callback(orderMode);
      }
    },
    errorCallback
  );

  const unsubscribe = () => {
    off(queryRef, "value", listener);
  };
  return unsubscribe;
}

export async function updateSiteMode(
  isOn: boolean,
  errorCallback: (error: Error) => void
) {
  try {
    await set(ref(database, `mode`), {
      orderMode: isOn,
    });
  } catch (error) {
    errorCallback(error);
  }
}

function countArrayValues(obj: any) {
  let counts = 0;

  Object.values(obj).forEach((arr: any[]) => {
    if (arr.length > 1) {
      arr.forEach((val) => {
        if (val !== true) {
          counts += val.orderAmount;
        }
      });
    }
  });

  return counts;
}

type ArrayWithTrue = [true] | [true, ...unknown[]];
type ObjectWithArrays = { [key: string]: ArrayWithTrue };

function mergeObjects(
  prev: ObjectWithArrays,
  update: ObjectWithArrays
): ObjectWithArrays {
  const result: ObjectWithArrays = { ...update };
  for (const [key, value] of Object.entries(prev)) {
    if (key in update) {
      result[key] = value;
    } else if (value.length > 1 || value[0] !== true) {
      result[key] = value;
    }
  }
  // Remove keys with value of [true]
  for (const [key, value] of Object.entries(result)) {
    if (value.length === 1 && value[0] === true) {
      delete result[key];
    }
  }
  return result;
}

async function addOrderSchedule(
  id: number,
  startTime: string,
  endTime: string,
  availableAmount: number
) {
  set(ref(database, `orderSchedule/${id}`), {
    id,
    availableAmount: availableAmount,
    timeslots: {
      ...generateTimeSlots(startTime, endTime).reduce(
        (o, key) => ({ ...o, [key]: [true] }),
        {}
      ),
    },
  });
}

function generateTimeSlots(startTime: string, endTime: string) {
  const slotInterval = 15;
  const start = moment(startTime, "HH:mm");
  const end = moment(endTime, "HH:mm");
  let timeSlots = [];
  while (start <= end) {
    timeSlots.push(start.format("HH:mm"));
    start.add(slotInterval, "minute");
  }
  return timeSlots;
}

export async function getOrderScheduleFromToday() {
  return get(ref(database, "orderSchedule")).then((snapshot) => {
    if (snapshot.exists()) {
      return snapshot.val();
    }
    return [];
  });
}

export async function listenOrderAvailabilityByDateId(
  orderDayId: string,
  callback: (schedule: any) => void,
  errorCallback: (error: Error) => void
) {
  const availabilityRef = ref(
    database,
    `orderSchedule/${orderDayId}/availableAmount`
  );
  const listener = onValue(
    availabilityRef,
    (snapshot) => {
      if (snapshot.exists()) {
        callback(+snapshot.val());
      }
    },
    errorCallback
  );
  const unsubscribe = () => {
    off(availabilityRef, "value", listener);
  };
  return unsubscribe;
}

export async function getOrderScheduleByDateId(orderDayId: string) {
  return get(ref(database, `orderSchedule/${orderDayId}/timeslots`)).then(
    (snapshot) => {
      if (snapshot.exists()) {
        return { dateId: orderDayId, timeslots: snapshot.val() };
      }
      return [];
    }
  );
}

export async function listenOrderScheduleByDateId(
  orderDayId: string,
  callback: (schedule: any) => void,
  errorCallback: (error: Error) => void
) {
  const timeslotRef = ref(database, `orderSchedule/${orderDayId}/timeslots`);
  const listener = onValue(
    timeslotRef,
    (snapshot) => {
      if (snapshot.exists()) {
        callback({ dateId: orderDayId, timeslots: snapshot.val() });
      }
    },
    errorCallback
  );
  const unsubscribe = () => {
    off(timeslotRef, "value", listener);
  };
  return unsubscribe;
}

export async function getOrderDay(orderDayId: string) {
  return get(ref(database, `orderDays/${orderDayId}`)).then((snapshot) => {
    if (snapshot.exists()) {
      return Object.values(snapshot.val());
    }
    return [];
  });
}

export async function addMenu(
  product: string,
  menu: any,
  errorCallback: (error: Error) => void
) {
  const id = uuid();

  try {
    await set(ref(database, `menu/${product}/${id}`), {
      ...menu,
      id,
    });
  } catch (error) {
    console.error("Error adding order day:", error);
    errorCallback(error);
  }
}

export async function getAllMenu() {
  return get(ref(database, "menu")).then((snapshot) => {
    if (snapshot.exists()) {
      return Object.entries(snapshot.val()).reduce(
        (a, v) => ({ ...a, [v[0]]: Object.values(v[1]) }),
        {}
      );
    }
    return {};
  });
}

export async function getMenu(product: string) {
  return get(ref(database, `menu/${product}`)).then((snapshot) => {
    if (snapshot.exists()) {
      return Object.values(snapshot.val());
    }
    return [];
  });
}

export async function listenMenu(
  callback: (menuList: MenuList) => void,
  errorCallback: (error: Error) => void
) {
  const queryRef = query(ref(database, `menu`));
  const listener = onValue(
    queryRef,
    (snapshot) => {
      if (snapshot.exists()) {
        callback(snapshot.val());
      }
    },
    errorCallback
  );
  const unsubscribe = () => {
    off(queryRef, "value", listener);
  };
  return unsubscribe;
}

// export async function listenMenuByProduct(
//   product: string,
//   callback: (orderDays: any[]) => void,
//   errorCallback: (error: Error) => void
// ) {
//   const queryRef = query(ref(database, `menu/${product}`));
//   const listener = onValue(
//     queryRef,
//     (snapshot) => {
//       if (snapshot.exists()) {
//         const menuList = Object.values(snapshot.val());
//         callback(menuList);
//       }
//     },
//     errorCallback
//   );
//   const unsubscribe = () => {
//     off(queryRef, 'value', listener);
//   };
//   return unsubscribe;
// }

export async function removeMenu(
  product: string,
  menuId: string,
  errorCallback: (error: Error) => void
) {
  const menuRef = ref(database, `menu/${product}/${menuId}`);
  try {
    await remove(menuRef);
  } catch (error) {
    console.error(`Error removing menu: ${error}`);
    errorCallback(error);
  }
}

export async function updateMenu(
  product: string,
  menu: Menu,
  errorCallback: (error: Error) => void
) {
  const menuRef = ref(database, `menu/${product}/${menu.id}`);

  try {
    await update(menuRef, menu);
  } catch (error) {
    console.error(`Error update menu: ${error}`);
    errorCallback(error);
  }
}

export async function removeOrderDay(
  orderDayId: string,
  errorCallback: (error: Error) => void
) {
  try {
    const updates: Record<string, any> = {};
    updates[`orderDays/${orderDayId}`] = null;
    updates[`orderSchedule/${orderDayId}`] = null;

    await update(ref(database), updates);
  } catch (error) {
    console.error(`Error delete order day: ${error}`);
    errorCallback(error);
  }
}

export async function listenOrdersByOrderDayId(
  orderDayId: string,
  callback: (orderDays: any[]) => void,
  errorCallback: (error: Error) => void
) {
  const orderRef = ref(database, "orders");
  const queryRef = query(
    orderRef,
    orderByChild("dateId"),
    equalTo(+orderDayId)
  );
  const listener = onValue(
    queryRef,
    (snapshot) => {
      if (snapshot.exists()) {
        const orders = Object.values(snapshot.val());
        callback(orders);
      }
    },
    errorCallback
  );
  const unsubscribe = () => {
    off(queryRef, "value", listener);
  };
  return unsubscribe;
}

export async function getOrderByPaymentIntent(paymentIntent: string) {
  const orderRef = ref(database, "/orders");

  const queryRef = await query(
    orderRef,
    orderByChild("paymentIntent"),
    equalTo(paymentIntent)
  );

  return get(queryRef).then((snapshot) => {
    if (snapshot.exists()) {
      return Object.values(snapshot.val()).sort(
        (a: any, b: any) => a.dateId - b.dateId
      );
    }
    return [];
  });
}

export const addOrder = httpsCallable(functions, "addOrder");

export const addOrderAndUdateSchedule = httpsCallable(
  functions,
  "addOrderAndUpdateSchedule"
);

export const updateOrderAndUdateSchedule = httpsCallable(
  functions,
  "updateOrderAndUdateSchedule"
);

export const deleteOrderAndSchedule = httpsCallable(
  functions,
  "deleteOrderAndSchedule"
);

export const processPayment = httpsCallable(functions, "processPayment");

export async function updateTimeslotStatus(
  dateId: string,
  pickupTime: string,
  value: boolean
) {
  const scheduleRef = ref(database, `orderSchedule/${dateId}`);
  try {
    await runTransaction(scheduleRef, (schedule) => {
      if (schedule) {
        const timeslots = schedule.timeslots;
        if (timeslots) {
          timeslots[pickupTime][0] = value;
        }
      }
      return schedule;
    });
  } catch (error) {
    console.error("Error updating timeslot status:", error);
  }
}
