import React, { useState, useEffect } from 'react';
import { listenOrderScheduleByDateId } from '../../../../api/firebase';
import { ColorRing } from 'react-loader-spinner';
import { MAX_CHICKEN_ORDER, SERVER_ERR_MSG } from '../../../../common/constant';
import Text from '../../../../components/Text';
import { countSlotOrderAmount, isAbleTimeSlot } from '../../../../common/utils';
import { useSingleOrderContext } from '../../../../components/context/SingleOrderContext';

function TimeSlots({
  dateId,
  handleSlotClick,
}: {
  dateId: string;
  handleSlotClick: (slot: string) => void;
}) {
  const [schedule, setSchdule] = useState(undefined);
  const [error, setError] = useState(undefined);
  useEffect(() => {
    let unsubscribe: () => void;
    listenOrderScheduleByDateId(
      dateId,
      (schedule) => {
        setSchdule(schedule);
      },
      (error) => {
        console.error('Error setting up listener:', error);
        setError(SERVER_ERR_MSG);
      }
    ).then((unsub) => {
      unsubscribe = unsub;
    });
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  const { order, updateOrder, getTotalChickenAmount } = useSingleOrderContext();
  const [clickedButton, setClickedButton] = useState(undefined);
  const onSlotClick = (slot: string) => {
    handleSlotClick(slot);
    setClickedButton(slot);
  };

  const resetPickupTime = () => {
    setClickedButton(undefined);
    updateOrder(
      order.dateId === dateId ? { ...order, pickupTime: undefined } : order
    );
  };

  useEffect(() => {
    !!order.pickupTime && setClickedButton(order.pickupTime);
  }, []);

  return (
    <div className='container flex flex-wrap justify-center'>
      <>
        {error && <h2 className='text-2xl text-center'>{error}</h2>}
        {schedule === undefined && (
          <ColorRing
            visible={true}
            height='80'
            width='80'
            ariaLabel='blocks-loading'
            wrapperClass='blocks-wrapper'
            colors={['#8a0917', '#fcdd89', '#f8b26a', '#abbd81', '#8a0917']}
          />
        )}
        <div className='container flex flex-wrap justify-center'>
          {schedule !== undefined &&
            Object.keys(schedule?.timeslots).map((slot: string) => {
              const numberOfOrders = countSlotOrderAmount(
                schedule?.timeslots[slot]
              );
              const isOpen = schedule?.timeslots[slot][0];
              const ableToPick =
                isOpen &&
                isAbleTimeSlot(numberOfOrders) &&
                numberOfOrders + getTotalChickenAmount() < MAX_CHICKEN_ORDER + 1
                  ? true
                  : false;
              if (slot === clickedButton && !ableToPick) {
                resetPickupTime();
              }
              return (
                <div className='relative p-2' key={slot}>
                  <button
                    disabled={!ableToPick}
                    key={slot}
                    className={`inline-block bg-gray-200 rounded-lg px-5 py-3 text-sm font-semibold mr-2 mb-2 text-lg ${
                      !ableToPick && 'opacity-25'
                    } ${clickedButton === slot && 'bg-brand text-white'}`}
                    onClick={() => onSlotClick(slot)}
                  >
                    {slot}
                  </button>
                  {!ableToPick && (
                    <div className='absolute top-1 left-5 origin-bottom -rotate-12 text-brand text-xs'>
                      <Text tid='full' />
                    </div>
                  )}
                </div>
              );
            })}
        </div>
      </>
    </div>
  );
}

export default TimeSlots;
