import React, { useState, useEffect, useRef } from "react";
import {
  deleteOrderAndSchedule,
  listenOrdersByOrderDayId,
  listenOrderScheduleByDateId,
  updateOrderAndUdateSchedule,
  updateTimeslotStatus,
} from "../../api/firebase";
import { useParams } from "react-router-dom";
import {
  countSlotOrderAmount,
  isAbleTimeSlot,
  timeStampToString,
  toEuro,
} from "./../../common/utils";
import {
  FaCheckSquare,
  FaEye,
  FaPencilAlt,
  FaPlus,
  FaTrash,
} from "react-icons/fa";
import { Box, Modal, Typography } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import AddOrderForm from "./components/AddOrderForm";
import DateFormatter from "../../components/DateFormatter";
import { Order } from "../../types/order";
import ProductDetails from "./components/ProductDetails";
import OrderOverviewTable from "./components/OrderOverviewTable";
import ReactToPrint from "react-to-print";
import {
  SERVER_ERR_MSG,
  deleteModalstyle,
  modalStyle,
} from "../../common/constant";
import Text from "../../components/Text";
import { useSingleOrderContext } from "../../components/context/SingleOrderContext";
import { v4 as uuid } from "uuid";

const OrderDayDetails = () => {
  const { dateId } = useParams();
  const [orders, setOrders] = useState<Order[]>(undefined);
  const [filteredOrders, setFilteredOrders] = useState<Order[]>(undefined);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [detailModalOpen, setDetailModalOpen] = useState(false);
  const [schedule, setSchedule] = useState(undefined);
  const componentRef = useRef();
  const [error, setError] = useState(undefined);
  const { updateOrder, order, reset } = useSingleOrderContext();

  useEffect(() => {
    updateOrder({
      dateId: dateId,
      date: timeStampToString(+dateId),
      id: uuid(),
    });
    let unsubscribeForOrders: () => void;
    listenOrdersByOrderDayId(
      dateId,
      (data) => {
        setOrders(data);
        setFilteredOrders(
          data.filter((order) => order.paymentStatus !== undefined)
        );
      },
      (error) => {
        console.error("Error setting up listener:", error);
        setError(SERVER_ERR_MSG);
      }
    ).then((unsub) => {
      unsubscribeForOrders = unsub;
    });

    let unsubscribeForSchedule: () => void;
    listenOrderScheduleByDateId(
      dateId,
      (schedule) => {
        setSchedule(schedule);
      },
      (error) => {
        console.error("Error setting up listener:", error);
        setError(SERVER_ERR_MSG);
      }
    ).then((unsub) => {
      unsubscribeForSchedule = unsub;
    });
    return () => {
      if (unsubscribeForOrders) {
        unsubscribeForOrders();
      }
      if (unsubscribeForSchedule) {
        unsubscribeForSchedule();
      }
    };
  }, [dateId]);

  const onTimeslotStatusChange = (slot: string, isOpen: string) => {
    const status = isOpen === "false" ? false : true;
    updateTimeslotStatus(dateId, slot, status);
  };

  const handlePaymentComplete = (order: Order) => {
    const shouldUpdateTimeslots = false;
    updateOrderAndUdateSchedule({
      id: order.id,
      orderData: {
        ...order,
        paid: true,
        totalPaidAmount: order.currentOrderPrice,
      },
      shouldUpdateTimeslots: shouldUpdateTimeslots,
    })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setDeleteModalOpen(false);
        reset();
      });
  };

  const deleteOrderDay = (
    id: string,
    dateId: string,
    pickupTime: string,
    amount: number,
    isCanceledOrder: boolean
  ) => {
    deleteOrderAndSchedule({ id, dateId, pickupTime, amount, isCanceledOrder })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setDeleteModalOpen(false);
        reset();
      });
  };

  const handleError = (err: any) => {
    setError(err);
  };

  return (
    <div className="mt-[90px] flex flex-col justify-center items-center">
      {error && <h2 className="text-2xl text-center">{error}</h2>}
      <div>
        <Modal
          open={addModalOpen}
          onClose={() => {
            setAddModalOpen(false);
            reset();
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{ ...modalStyle, overflow: "scroll", height: "100%" }}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Add New Order on {/* @ts-expect-error Server Component */}
              <DateFormatter date={timeStampToString(+dateId)} />
            </Typography>
            <AddOrderForm
              handleClose={() => setAddModalOpen(false)}
              dateId={dateId}
              order={order}
              isEdit={false}
              setError={handleError}
            />
          </Box>
        </Modal>
      </div>
      <div>
        <Modal
          open={editModalOpen}
          onClose={() => {
            setEditModalOpen(false);
            reset();
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{ ...modalStyle, overflow: "scroll", height: "100%" }}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Edit Order on {/* @ts-expect-error Server Component */}
              <DateFormatter date={timeStampToString(+dateId)} />
            </Typography>
            <AddOrderForm
              handleClose={() => setEditModalOpen(false)}
              dateId={dateId}
              order={order}
              isEdit={true}
              setError={handleError}
            />
          </Box>
        </Modal>
      </div>
      <div>
        <Modal
          open={deleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={deleteModalstyle}>
            <h2 className="text-2xl text-red-700">Delete order</h2>
            <p className="">Do you really want to delete the order?</p>
            <div className="flex justify-center gap-5 mt-8">
              <button
                className="border border-red-700  p-2 rounded-lg"
                onClick={() => {
                  setDeleteModalOpen(false);
                }}
              >
                Cancel
              </button>
              <button
                className="bg-red-700 text-white p-2 rounded-lg"
                onClick={() => {
                  deleteOrderDay(
                    order.id,
                    dateId,
                    order.pickupTime,
                    order.totalChickenBoxCount,
                    order.paymentStatus === undefined
                  );
                }}
              >
                Delete
              </button>
            </div>
          </Box>
        </Modal>
      </div>
      <div>
        <Modal
          open={detailModalOpen}
          onClose={() => {
            setDetailModalOpen(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Product Details
            </Typography>
            <ProductDetails products={order?.products} />
            <button
              className=" w-full bg-brand p-3 text-white mt-5 rounded-md"
              onClick={() => setDetailModalOpen(false)}
            >
              Close
            </button>
          </Box>
        </Modal>
      </div>
      <div className="my-10 w-[90%] min-w-[300px] max-md:w-[98%] ">
        <div className="mb-2">
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/admin">
              Order Days
            </Link>
            <Typography color="text.primary">
              {/* @ts-expect-error Server Component */}
              <DateFormatter date={timeStampToString(+dateId)} />
            </Typography>
          </Breadcrumbs>
        </div>

        <div className="w-full flex gap-5 justify-center items-center">
          <h1 className="text-2xl font-bold ">
            Order Date - {/* @ts-expect-error Server Component */}
            <DateFormatter date={timeStampToString(+dateId)} />
          </h1>
          <ReactToPrint
            trigger={() => (
              <button className="rounded-md bg-brand text-white p-2 flex justify-center items-center gap-2">
                Print
              </button>
            )}
            content={() => componentRef.current}
          />
        </div>

        <div className="w-full flex justify-end my-5 gap-3">
          <button
            className="rounded-md bg-brand text-white p-2 flex justify-center items-center gap-2"
            onClick={() => {
              setAddModalOpen(true);
            }}
          >
            <FaPlus color="white" /> New order
          </button>
        </div>
        <table className="table-auto w-full border-separate border border-slate-400 ">
          <thead>
            <tr>
              <th className="border border-slate-300 ">Order Number</th>
              <th className="border border-slate-300 ">Customer</th>
              <th className="border border-slate-300 ">Phone</th>
              <th className="border border-slate-300 ">Pick-up Time</th>
              <th className="border border-slate-300 ">Paid</th>
              <th className="border border-slate-300 ">Order Price</th>
              <th className="border border-slate-300 ">Paid Total</th>
              <th className="border border-slate-300 ">Product</th>
              <th className="border border-slate-300 ">Edit</th>
              <th className="border border-slate-300 ">Delete</th>
            </tr>
          </thead>
          <tbody className="text-center">
            {orders?.map((order: Order) => (
              <tr key={order.id}>
                <td className="border border-slate-300 ">
                  {order.orderNumber}
                </td>
                <td className="border border-slate-300 ">
                  {order.customer.firstname} {order.customer.lastname}
                </td>

                <td className="border border-slate-300 ">
                  {order.customer.phone}
                </td>
                <td className="border border-slate-300 ">{order.pickupTime}</td>
                <td className="border border-slate-300">
                  {order.paid ? (
                    <div className="flex justify-center">
                      <FaCheckSquare color="green" />
                    </div>
                  ) : (
                    <span className="text-red-600">x</span>
                  )}
                </td>
                <td className="border border-slate-300 ">
                  {toEuro(+order.currentOrderPrice)}
                </td>
                <td className="border border-slate-300 ">
                  <div className="flex flex-wrap gap-2 justify-center">
                    {toEuro(+order.totalPaidAmount)}
                    {order.paymentMethod === undefined ? (
                      <span className="text-red-500">Payment Canceled</span>
                    ) : (
                      order.totalPaidAmount !== order.currentOrderPrice &&
                      order.paymentIntent === undefined && (
                        <button
                          onClick={() => handlePaymentComplete(order)}
                          className="bg-brand p-1 rounded-md text-white text-sm drop-shadow-md"
                        >
                          Complete
                        </button>
                      )
                    )}
                  </div>
                </td>
                <td className="border border-slate-300 ">
                  <button
                    className="rounded-md p-1 flex justify-center items-center w-full"
                    onClick={() => {
                      updateOrder(order);
                      setDetailModalOpen(true);
                    }}
                  >
                    <FaEye />
                  </button>
                </td>
                <td className="border border-slate-300 text-center">
                  <button
                    className="rounded-md  p-1 flex justify-center items-center w-full"
                    onClick={() => {
                      updateOrder(order);
                      setEditModalOpen(true);
                    }}
                  >
                    <FaPencilAlt color="black" />
                  </button>
                </td>
                <td className="border border-slate-300 text-center">
                  <button
                    className="rounded-md  text-white p-1 flex justify-center items-center w-full "
                    onClick={() => {
                      updateOrder(order);
                      setDeleteModalOpen(true);
                    }}
                  >
                    <FaTrash color="red" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="w-full flex flex-col gap-5 justify-center items-center mt-10">
          <h1 className="text-2xl font-bold ">Timeslots</h1>
          <div>slots</div>
          <div className="container flex flex-wrap justify-center">
            {schedule !== undefined &&
              Object.keys(schedule?.timeslots).map((slot: string) => {
                const numberOfOrders = countSlotOrderAmount(
                  schedule?.timeslots[slot]
                );
                const isOpen = schedule?.timeslots[slot][0];

                const ableToPick = isAbleTimeSlot(numberOfOrders);
                return (
                  <div className="relative p-2" key={slot}>
                    <div className="flex flex-col">
                      <span
                        key={slot}
                        className={`inline-block bg-gray-200 rounded-lg px-5 py-3 text-sm font-semibold mr-2 mb-2 text-lg ${
                          (!ableToPick || !isOpen) && "opacity-25"
                        }`}
                      >
                        {slot}
                      </span>

                      <select
                        disabled={!ableToPick}
                        name="timeslotStatus"
                        id="timeslotStatus"
                        value={isOpen}
                        onChange={(e) =>
                          onTimeslotStatusChange(slot, e.target.value)
                        }
                        className={`text-sm ${
                          isOpen && "bg-green-600 rounded-md text-white"
                        }`}
                      >
                        <option value="true">Open</option>
                        <option value="false">Close</option>
                      </select>
                    </div>

                    {!ableToPick && (
                      <div className="absolute top-1 left-5 origin-bottom -rotate-12 text-brand text-xs">
                        <Text tid="full" />
                      </div>
                    )}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <div
        className="my-10 w-[90%] min-w-[300px] max-md:w-[98%] "
        ref={componentRef}
      >
        <OrderOverviewTable dateId={dateId} orders={filteredOrders} />
      </div>
    </div>
  );
};

export default OrderDayDetails;
