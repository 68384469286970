import { createContext, useState } from 'react';
import { dictionaryList } from '../languages/language';

export const LanguageContext = createContext();

export function LanguageContextProvider({ children }) {
  const defaultLanguage = window.localStorage.getItem('rcml-lang');
  const userDefaultLanguage = defaultLanguage === 'en' ? 'GB' : 'NL';
  const [userLanguage, setUserLanguage] = useState(userDefaultLanguage || 'GB');
  const [dictionary, setDictionary] = useState(dictionaryList[userLanguage]);

  const userLanguageChange = (selected) => {
    setUserLanguage(selected);
    window.localStorage.setItem('rcml-lang', selected);
    setDictionary(dictionaryList[selected]);
  };

  return (
    <LanguageContext.Provider
      value={{ userLanguage, dictionary, userLanguageChange }}
    >
      {children}
    </LanguageContext.Provider>
  );
}
