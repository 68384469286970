import React, { useState, createRef, useEffect } from "react";
import { Link } from "react-router-dom";
import LanguageSelector from "./LanguageSelector";
import Text from "./Text";
import { RxHamburgerMenu } from "react-icons/rx";
import { useAuthContext } from "./context/AuthContext";
import { FiShoppingBag } from "react-icons/fi";
import { useSingleOrderContext } from "./context/SingleOrderContext";
import { AiFillCloseCircle } from "react-icons/ai";
import Cart from "./cart/Cart";
import { listenSiteMode } from "../api/firebase";

function NavBar() {
  const [menuClicked, setMenuClicked] = useState(false);
  const menuRef = createRef<HTMLDivElement>();
  const cartRef = createRef<HTMLDivElement>();
  const cartBtnRef = createRef<HTMLButtonElement>();
  const cartBtnRef2 = createRef<HTMLButtonElement>();
  const { user, logout } = useAuthContext();
  const { cartOpen, toggleCartOpen, setCartOpen, getTotalProductAmount } =
    useSingleOrderContext();
  const [orderMode, setOrderMode] = useState<boolean>(undefined);
  const [modeError, setModeError] = useState(undefined);

  const onMenuClick = () => {
    setMenuClicked(!menuClicked);
  };

  const handleClickOutside = (e: any) => {
    if (menuRef.current && !menuRef.current.contains(e.target)) {
      setMenuClicked(false);
    }
    if (
      cartRef.current &&
      !cartRef.current.contains(e.target) &&
      !cartBtnRef.current.contains(e.target) &&
      !cartBtnRef2.current.contains(e.target)
    ) {
      setCartOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    let unsubscribeForOrderMode: () => void;

    listenSiteMode(
      (orderMode) => {
        setOrderMode(orderMode);
      },
      (error) => {
        setModeError(error);
      }
    ).then((unsub) => {
      unsubscribeForOrderMode = unsub;
    });
  }, []);

  return (
    <header className="flex justify-between p-4 gap-5 fixed w-full top-0 max-w-screen-2xl bg-white z-[100] shadow-md items-center">
      <Link
        to="/"
        className="flex items-end text-4xl font-extrabold text-brand gap-2 justify-end"
      >
        <img
          src="/chimek-logo-without-name.svg"
          alt="Chimek-logo"
          className="w-20"
        />
        <h1>Chimek</h1>
      </Link>
      <nav className="flex justify-between gap-10 font-semibold items-center max-[768px]:hidden">
        <Link to="/menu">
          <Text tid="menu" />
        </Link>
        <Link to="/aboutus">
          <Text tid="aboutUs" />
        </Link>
        {orderMode && (
          <button
            ref={cartBtnRef}
            onClick={toggleCartOpen}
            className="bg-yellow h-10 w-fit px-3  justify-center items-center rounded-lg relative hover:scale-105 duration-100 shadow-md hover:shadow-fontColor"
          >
            <FiShoppingBag size={30} />
            <span className="text-sm absolute bottom-0 right-0 bg-brand text-white h-[20px] w-[20px] rounded-[30%] flex justify-center items-center">
              {getTotalProductAmount()}
            </span>
          </button>
        )}

        {user?.isAdmin ? (
          <button
            onClick={logout}
            className="bg-brand text-white rounded-lg p-4 h-9 flex items-center justify-center hover:scale-105 duration-100 shadow-md hover:shadow-fontColor"
          >
            Logout
          </button>
        ) : (
          orderMode && (
            <Link
              to="/order"
              className="bg-brand text-white rounded-lg p-4 h-9 flex items-center justify-center hover:scale-105 duration-100 shadow-md hover:shadow-fontColor"
            >
              <Text tid="orderButton" />
            </Link>
          )
        )}

        <LanguageSelector />
      </nav>
      <div className="md:hidden flex gap-3 items-center">
        {orderMode && (
          <button
            ref={cartBtnRef2}
            onClick={toggleCartOpen}
            className="bg-yellow h-10 w-fit px-3 justify-center items-center rounded-lg relative hover:scale-105 duration-100 shadow-md hover:shadow-fontColor"
          >
            <FiShoppingBag size={28} />
            <span className="text-sm absolute bottom-0 right-0 bg-brand text-white h-[20px] w-[20px] rounded-[30%] flex justify-center items-center">
              {getTotalProductAmount()}
            </span>
          </button>
        )}

        <RxHamburgerMenu
          size={"30px"}
          style={{ cursor: "pointer" }}
          className="md:hidden"
          onClick={onMenuClick}
        />
        <nav
          className={`ease-in-out duration-300 flex flex-col absolute h-screen w-[70%] bg-[#333333] top-[100px] left-0 bottom-0 p-12 gap-5 text-white text-2xl md:hidden ${
            !menuClicked && "translate-x-[-100%]"
          }`}
          ref={menuRef}
        >
          <Link to="/menu" onClick={onMenuClick}>
            <Text tid="menu" />
          </Link>
          <Link to="/aboutus" onClick={onMenuClick}>
            <Text tid="aboutUs" />
          </Link>
          {user?.isAdmin ? (
            <button className="bg-brand text-white rounded-lg p-4 h-9 flex items-center justify-center hover:scale-105 duration-100 shadow-md hover:shadow-fontColor">
              Logout
            </button>
          ) : (
            orderMode && (
              <Link to="/order" onClick={onMenuClick}>
                <Text tid="orderButton" />
              </Link>
            )
          )}

          <LanguageSelector />
        </nav>
      </div>
      <div
        className={`ease-in-out duration-300 flex flex-col absolute h-screen w-[90%] min-w-[250px] max-w-[450px] md:w-[40%] bg-[#fff] top-[100px] right-0 bottom-[-100px] p-4  ${
          !cartOpen && "invisible translate-x-[200%] "
        } ${cartOpen && "shadow-2xl visible"}`}
        ref={cartRef}
      >
        <div className="overflow-y-scroll h-fit pb-20">
          <div className="flex justify-between items-center my-1">
            <h1 className="text-xl my-3 font-bold">
              <Text tid="myCart" />
            </h1>
            <button onClick={() => setCartOpen(false)}>
              <AiFillCloseCircle size={30} color="grey" />
            </button>
          </div>
          <hr />
          <Cart />
        </div>
      </div>
    </header>
  );
}

export default NavBar;
