import React from "react";
import Text from "./Text";
import { FaFacebook, FaInstagram, FaWhatsapp, FaTwitter } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="h-full bg-brand text-white p-5 pt-12  md:px-[10rem]">
      <div className="flex flex-col flex-wrap items-center justify-between md:flex-row md:items-start">
        <div className="flex justify-between  gap-10 md:w-[30%] ">
          <ul className="flex flex-col gap-2 flex-wrap text-sm">
            <h2 className="text-lg font-extrabold mb-3 text-yellow">
              <Text tid="pages" />
            </h2>
            <li className="hover:scale-105 hover:font-bold duration-100">
              <a href="/menu">
                <Text tid="menu" />
              </a>
            </li>
            <li className="hover:scale-105 hover:font-bold duration-100">
              <a href="/aboutus">
                <Text tid="aboutUs" />
              </a>
            </li>
            <li className="hover:scale-105 hover:font-bold duration-100">
              <a href="/policy">
                <Text tid="policy" />
              </a>
            </li>
            <li className="hover:scale-105 hover:font-bold duration-100">
              <a href="/contactus">
                <Text tid="contactUs" />
              </a>
            </li>
          </ul>

          <ul className="flex flex-col gap-2 flex-wrap text-sm">
            <h2 className="text-lg font-extrabold mb-3 text-yellow">
              <Text tid="followUs" />
            </h2>
            <li className="hover:scale-105 hover:font-bold duration-100">
              <a
                target="_blank"
                href="https://www.facebook.com/profile.php?id=100090776049222"
                className="flex  items-center gap-1"
                rel="noreferrer"
              >
                <FaFacebook size={"1.3rem"} />
                <Text tid="facebook" />
              </a>
            </li>
            <li className="hover:scale-105 hover:font-bold duration-100">
              <a
                target="_blank"
                href="https://instagram.com/chimeknl?igshid=ZDdkNTZiNTM="
                className="flex  items-center gap-1"
                rel="noreferrer"
              >
                <FaInstagram size={"1.3rem"} />
                <Text tid="instagram" />
              </a>
            </li>
          </ul>
        </div>

        <div className="h-full flex flex-col justify-center items-center mt-5 gap-3">
          <img
            className="max-h-[100px]"
            src="/chimek-logo-white.png"
            alt="chimek_logo"
          />
          <p className="text-xs">KvK nr: 89377133 </p>
        </div>
      </div>
      <p className="text-center mt-[50px] text-sm">
        &copy; Chimek 2024 - All Rights Reserved-
      </p>
    </footer>
  );
};

export default Footer;
