import { MAX_CHICKEN_ORDER } from './constant';

export function timeStampToString(timestamp: number) {
  return new Date(timestamp).toISOString().slice(0, 10);
}

export function toTimeStamp(dateString: string) {
  return new Date(dateString).getTime();
}

export function toEuro(number: number) {
  return `€ ${number.toFixed(2)}`;
}

export const countSlotOrderAmount = (slotOrders: any[]) => {
  let count = 0;
  slotOrders.forEach((order) => {
    if (order.orderAmount) {
      count += +order.orderAmount;
    }
  });
  return count;
};

export const isAbleTimeSlot = (currentOrderCount: number) => {
  return currentOrderCount < MAX_CHICKEN_ORDER;
};

export const titleCase = (string: string) => {
  return string[0].toUpperCase() + string.substr(1).toLowerCase();
};
