import React from "react";
import Text from "../components/Text";
import { Helmet } from "react-helmet-async";
import { FaEnvelope, FaPhone, FaWhatsapp } from "react-icons/fa";

const AboutUs = () => {
  return (
    <>
      <Helmet>
        <title>About Us</title>
        <meta
          name="description"
          content="Bij Chimek zijn we er trots op de beste Koreaanse gefrituurde kipervaring aan onze klanten te bieden, en voor nu doen we dat allemaal via handige online bestelling en afhalen op locatie."
        />
        <link rel="canonical" href="/aboutus" />
      </Helmet>
      <div className="mt-[90px]">
        <div className="menuShowcase h-[17vh] flex justify-center items-center md:h-[25vh]">
          <h1 className="text-3xl md:text-5xl font-bold">
            <Text tid="aboutUs" />
          </h1>
        </div>

        <div className="my-[5rem] md:px-[10rem] grid grid-cols-1 gap-10 p-3 md:grid-cols-2 items-center">
          <div className="md:col-span-2">
            <h3 className="text-3xl font-extrabold mb-5">
              <Text tid="firstTitle" />
            </h3>
            <p className="text-lg">
              <Text tid="firstParagraph" />
            </p>
          </div>
          <img
            className="w-full h-full"
            src="https://images.unsplash.com/photo-1626645738196-c2a7c87a8f58?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80"
            alt=""
          />
          <div>
            <h3 className="text-xl font-extrabold">
              <Text tid="secondTitle" />
            </h3>
            <p className="text-lg">
              <Text tid="secondParagraph" />
            </p>
          </div>

          <div>
            <h3 className="text-xl font-extrabold">
              <Text tid="thirdTitle" />
            </h3>
            <p className="text-lg">
              <Text tid="thirdParagraph" />
            </p>
          </div>
          <img
            className="w-full h-full"
            src="https://images.unsplash.com/photo-1626082927389-6cd097cdc6ec?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
            alt=""
          />
          <div className="md:col-span-2">
            <h3 className="text-xl font-extrabold">
              <Text tid="forthTitle" />
            </h3>
            <p className="text-lg">
              <Text tid="forthParagraph" />
            </p>
          </div>
        </div>
        <div className="flex flex-col max-h-screen h-screen bg-yellow justify-center px-5 md:px-[10rem]">
          <div className="h-fit flex flex-col items-center max-w-screen-lg mx-auto">
            <h2 className="text-brand text-4xl font-bold mt-[40px] sm:text-5xl">
              <Text tid="catering" />
            </h2>
            <p className="text-lg text-center mt-[20px]">
              <Text tid="cateringFirstSentence" />
              <br />
              <Text tid="cateringSecondSentence" />
              <br />
              <Text tid="cateringThirdSentence" />
            </p>
            <div className="flex gap-[1rem] sm:gap-[8rem] w-full justify-center flex-wrap my-10 mb-[40px]">
              <div className="flex flex-col gap-2 justify-center items-center min-w-[10rem]">
                <FaEnvelope color="#8a0917" size={"5rem"} />
                <h1 className="text-2xl font-extrabold">Email</h1>
                <a
                  className="text-lg"
                  href="https://mail.google.com/mail/?view=cm&fs=1&to=info@chimek.nl"
                >
                  info@chimek.nl
                </a>
              </div>
              <div className="flex flex-col gap-2 justify-center items-center min-w-[10rem] ">
                <FaPhone color="#8a0917" size={"5rem"} />
                <h1 className="text-2xl font-extrabold">
                  <Text tid="phone" />
                </h1>
                <a
                  href="https://api.whatsapp.com/send?phone=31641685401"
                  className="flex gap-2"
                >
                  <FaWhatsapp size={"1.3rem"} /> 0641685401
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
