import React, { useState, useEffect } from 'react';

import WizardButton from '../WizardButton';

import DateFormatter from '../../../../components/DateFormatter';
import { useSingleOrderContext } from '../../../../components/context/SingleOrderContext';
import TabView from './TabView';

type MenuFormProp = {
  handleClick: (step: string) => void;
  backToFirst: () => void;
};

function MenuForm({ handleClick, backToFirst }: MenuFormProp) {
  const { order } = useSingleOrderContext();

  const [canGoToNext, setCanGoToNext] = useState(false);
  useEffect(() => {
    order.products && Object.values(order.products).flat().length > 0
      ? setCanGoToNext(false)
      : setCanGoToNext(true);
  }, [order]);

  return (
    <div>
      <h2 className='text-3xl mt-2 bg-gradient-to-r from-brand font-bold text-white p-2 rounded-md w-[100%] mx-auto'>
        {/* @ts-expect-error Server Component */}
        <DateFormatter date={order.date} />
      </h2>
      <TabView />
      <WizardButton
        handleClick={handleClick}
        disabled={canGoToNext}
        warningMessage='menuSelectWarning'
      />
    </div>
  );
}

export default MenuForm;
