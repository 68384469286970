export const policyEnglish = [
  {
    title: "Thank you for choosing Chimek for your delicious meal! ",
    desc: "We respect your privacy and are committed to protecting your personal information. This privacy policy explains how we collect, use, and protect the information you provide when you place an order for pickup online.",
  },
  {
    title: "Information We Collect: ",
    desc: "When you place an order for pickup on our website, we collect the following personal information [Your name, Your phone number, Your email address, Your pickup date, Your pickup time, Your order details, Your payment details]",
  },
  {
    title: "How We Use Your Information: ",
    desc: "We use your personal information only for the purpose of processing your order and communicating with you about your order. We do not sell your information with any third parties. and will only make it available to third parties who are involved in the execution of an order on the website.",
  },
  {
    title: "Protection of Your Information: ",
    desc: "We take all reasonable steps to protect your personal information from unauthorized access, use, or disclosure. We use industry-standard encryption technology to protect your information during transmission. We also store your information securely in our database.",
  },
  {
    title: "Retention of Your Information: ",
    desc: "We retain your personal information only for as long as necessary to fulfill the purposes for which it was collected. We may also retain and use your information to comply with our legal obligations, resolve disputes, and enforce our agreements.",
  },
  {
    title: "Changes to Our Privacy Policy:  ",
    desc: "We may make changes to this privacy policy from time to time. Any changes we make will be posted on our website, so please check back periodically to stay informed.",
  },
  {
    title: "Contact Us: ",
    desc: "If you have any questions or concerns about this privacy policy, please contact us at info@chimek.nl ",
  },
  {
    title: "Order Cancellation Policy: ",
    desc: "If you need to cancel your order, please contact us at least 2 days before 23:59 on your scheduled pickup day. We may not be able to cancel your order if it has already been prepared or if your pickup day is within 2 days. If you cancel your order before the 2-day deadline, we will issue a refund. However, please note that a fee of 1 euro will be deducted from the refunded amount to cover transaction costs. For example: if your pickup day is Saturday, please contact us to cancel by Thursday 23:59 at the latest.",
  },
  {
    title: "Thank you for choosing Chimek!",
    desc: "",
  },
];

export const policyDutch = [
  {
    title: "Bedankt voor het kiezen van Chimek voor uw heerlijke maaltijd! ",
    desc: "We respecteren uw privacy en zijn toegewijd aan de bescherming van uw persoonlijke informatie. Dit privacybeleid legt uit hoe we de informatie die u verstrekt bij het plaatsen van een bestelling wordt verzameld, gebruikt en beschermd.",
  },
  {
    title: "Informatie die we verzamelen: ",
    desc: "Wanneer u een bestelling plaatst voor afhalen op onze website, verzamelen we de volgende persoonlijke informatie [Uw naam, Uw telefoonnummer, Uw e-mailadres, Uw afhaaldatum, Uw afhaaltijd, Uw bestelgegevens, Uw betalingsgegevens]",
  },
  {
    title: "Hoe we uw informatie gebruiken: ",
    desc: "We gebruiken uw persoonlijke informatie alleen voor het verwerken van uw bestelling en om met u te communiceren over uw bestelling. We verkopen uw informatie niet aan derden en stellen deze alleen beschikbaar aan derden die betrokken zijn bij de uitvoering van een bestelling op de website.",
  },
  {
    title: "Bescherming van uw informatie: ",
    desc: "We nemen alle redelijke maatregelen om uw persoonlijke informatie te beschermen tegen ongeoorloofde toegang, gebruik of openbaarmaking. We gebruiken encryptietechnologie die voldoet aan de industriestandaard om uw informatie tijdens verzending te beschermen. We slaan uw informatie ook veilig op in onze database.",
  },
  {
    title: "Bewaring van uw informatie: ",
    desc: "We bewaren uw persoonlijke informatie alleen zolang als nodig is om de doeleinden te vervullen waarvoor het werd verzameld. We kunnen uw informatie ook bewaren en gebruiken om te voldoen aan onze wettelijke verplichtingen, geschillen op te lossen en onze overeenkomsten af te dwingen.",
  },
  {
    title: "Wijzigingen in ons privacybeleid: ",
    desc: "We kunnen van tijd tot tijd wijzigingen aanbrengen in dit privacybeleid. Alle wijzigingen die we aanbrengen, zullen op onze website worden geplaatst, dus controleer deze regelmatig om op de hoogte te blijven.",
  },
  {
    title: "Neem contact met ons op: ",
    desc: "Als u vragen of opmerkingen heeft over dit privacybeleid, neem dan contact met ons op via info@chimek.nl ",
  },
  {
    title: "Annuleringsbeleid voor bestellingen: ",
    desc: "Als u uw bestelling wilt annuleren, neem dan tenminste 2 dagen voor uw geplande afhaaldag contact met ons op, vóór 23:59u. Daarna is annuleren niet meer mogelijk. Als u uw bestelling vóór de deadline van 2 dagen annuleert, zullen we een terugbetaling doen. Houd er echter wèl rekening mee dat er een bedrag van €1,- in mindering wordt gebracht. Dit doen wij om de transactiekosten te dekken. Bijvoorbeeld: uw afhaaldag is zaterdag, neem dan uiterlijk vóór donderdag 23:59u contact met ons op om de bestelling te annuleren.",
  },
  {
    title: "Bedankt voor het kiezen voor Chimek!",
    desc: "",
  },
];
