import { useContext } from 'react';
import { LanguageContext } from './../hooks/LanguageContext';

const DateFormatter = ({ date }: { date: string }) => {
  const dateFormat = new Date(date);
  const languageContext = useContext(LanguageContext);
  const currentLanguage = languageContext.userLanguage;
  const dutchDateSetting = 'nl-NL';
  const englishDateSetting = 'en-GB';
  return currentLanguage === 'nl'
    ? dateFormat.toLocaleDateString(dutchDateSetting)
    : dateFormat.toLocaleDateString(englishDateSetting);
};

export default DateFormatter;
