import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthContext } from '../components/context/AuthContext';

type ProtectedRouteProps = {
  children: React.ReactNode;
  requireAdmin: boolean;
};

const ProtectedRoute = ({ children, requireAdmin }: ProtectedRouteProps) => {
  const { user } = useAuthContext();

  if (!user) return <Navigate to='/login' />;
  if (user && requireAdmin && !user.isAdmin) {
    return <Navigate to='/' replace />;
  }

  return children;
};

export default ProtectedRoute;
