import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { SUCCEEDED, FAILD } from '../common/constant';
import Text from '../components/Text';
import { FaExclamationCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

function OrderPayment() {
  const [searchParams] = useSearchParams();
  const [paymentStatus, setPaymentStatus] = useState(undefined);
  const navigate = useNavigate();

  useEffect(() => {
    const status = searchParams.get('redirect_status');
    setPaymentStatus(status);
  }, [searchParams]);

  const toOrderPage = () => {
    navigate('/order');
  };

  return (
    <div className='mt-[90px] flex justify-center showcase'>
      {paymentStatus && paymentStatus === SUCCEEDED && (
        <div className='flex flex-col  items-center h-fit w-[65%] min-w-[300px] max-md:w-[95%] bg-white border shadow-xl rounded-2xl p-7  my-10'>
          <h2 className='text-3xl my-2 bg-brand font-bold text-white p-2 rounded-md w-[100%] mx-auto'>
            <Text tid='paymentSucceeded' />
          </h2>
          <h3 className='text-2xl font-bold mt-4'>
            <Text tid='orderConfirmation' />
          </h3>
          <div className='my-5 relative'>
            <img
              src='/fireworks.svg'
              alt='fireworks'
              className='w-[100px] absolute left-[-20%] rotate-[-40deg]'
            />
            <img
              src='/fireworks.svg'
              alt='fireworks'
              className='w-[100px] absolute right-[-15%] rotate-[40deg] '
            />
            <img
              src='/chimek-logo.svg'
              alt='Chimek-logo'
              className='w-[200px] '
            />
          </div>
          <div className='mb-5'>
            <p className='text-lg  mb-5 text-center md:mx-10'>
              <Text tid='orderSucceededNotice' />
            </p>
          </div>
        </div>
      )}
      {paymentStatus && paymentStatus === FAILD && (
        <div className='flex flex-col  items-center h-fit w-[65%] min-w-[300px] max-md:w-[95%] bg-white border shadow-xl rounded-2xl p-7  my-10'>
          <h2 className='text-3xl my-2 bg-brand font-bold text-white p-2 rounded-md w-[100%] mx-auto'>
            <Text tid='paymentFaild' />
          </h2>
          <div className='my-10'>
            <FaExclamationCircle color='#8a0917' size={'10rem'} />
          </div>
          <div className='mb-5'>
            <p className='text-md mt-10 mb-5 text-center md:mx-10'>
              <Text tid='orderFailedNotice' />
            </p>
          </div>
          <button
            className='mb-5 font-bold bg-brand text-white rounded-lg p-4 h-9 flex items-center justify-center hover:scale-105 duration-100 shadow-md hover:shadow-fontColor'
            onClick={toOrderPage}
          >
            <Text tid='goToOrder' />
          </button>
        </div>
      )}
    </div>
  );
}

export default OrderPayment;
