import React from "react";
import { FaEnvelope, FaPhone, FaWhatsapp } from "react-icons/fa";
import Accordion from "../components/Accordion";
import Text from "../components/Text";
import { Helmet } from "react-helmet-async";

const ContactUs = () => {
  return (
    <>
      <Helmet>
        <title>Contact Us</title>
        <meta
          name="description"
          content="Ons team van Chimek is gepassioneerd over eten en is toegewijd aan het bieden van uitstekende klantenservice."
        />
        <link rel="canonical" href="/contactus" />
      </Helmet>

      <div className="my-[90px]">
        <div className="menuShowcase h-[17vh] flex justify-center items-center md:h-[25vh] ">
          <h1 className="text-3xl md:text-5xl font-bold">
            <Text tid="contactUs" />
          </h1>
        </div>
        <div className=" md:px-[10rem] flex flex-col justify-center items-center gap-10">
          <h1 className="text-3xl font-extrabold my-10 ">Q&A</h1>
          <Accordion />
          <h1 className="text-3xl font-extrabold my-10">
            <Text tid="emailPhone" />
          </h1>
          <div className="flex gap-[8rem] w-full justify-center flex-wrap my-10">
            <div className="flex flex-col gap-2 justify-center items-center min-w-[10rem]">
              <FaEnvelope color="#8a0917" size={"5rem"} />
              <h1 className="text-2xl font-extrabold">Email</h1>
              <a
                className="text-lg"
                href="https://mail.google.com/mail/?view=cm&fs=1&to=info@chimek.nl"
              >
                info@chimek.nl
              </a>
            </div>
            <div className="flex flex-col gap-2 justify-center items-center min-w-[10rem]">
              <FaPhone color="#8a0917" size={"5rem"} />
              <h1 className="text-2xl font-extrabold">
                <Text tid="phone" />
              </h1>
              <a
                href="https://api.whatsapp.com/send?phone=31641685401"
                className="flex gap-2"
              >
                <FaWhatsapp size={"1.3rem"} /> 0641685401
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
