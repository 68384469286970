import React from 'react';
import { OrderContextProvider } from '../../components/context/OrderContext';
import Wizard from './components/Wizard';
import { Helmet } from 'react-helmet-async';

const Order = () => {
  return (
    <OrderContextProvider>
      <Helmet>
        <title>Order Online</title>
        <meta
          name='description'
          content='Bezoek eenvoudig onze website, kies een ophaaldatum, selecteer een ophaaltijd en kies uw items. Wij regelen de rest! U kunt ook voor meerdere datums tegelijk bestellen.'
        />
        <link rel='canonical' href='/order' />
      </Helmet>
      <div className=' min-h-[80vh] py-[100px] flex justify-center showcase bg-repeat-y'>
        <Wizard />
      </div>
    </OrderContextProvider>
  );
};

export default Order;
