import React, { memo, useState, useEffect } from 'react';
import { ColorRing } from 'react-loader-spinner';
import WizardButton from '../WizardButton';
import { OrderDay } from '../../../../types/orderDay';
import DayCard from '../../../../components/DayCard';
import useOrderDays from '../../../../hooks/useOrderDays';
import Text from '../../../../components/Text';
import { useSingleOrderContext } from '../../../../components/context/SingleOrderContext';
import { v4 as uuid } from 'uuid';
import { useParams } from 'react-router-dom';
import { timeStampToString } from '../../../../common/utils';

const DateForm = ({ handleClick }: { handleClick: (step: string) => void }) => {
  const { data: orderDays, isLoading, error } = useOrderDays();
  const { order, updateOrder } = useSingleOrderContext();
  const [selectedDateId, setSelectedDateId] = useState<number>();
  const { dateId } = useParams();

  useEffect(() => {
    if (dateId) {
      updateOrder({
        ...order,
        dateId: +dateId,
        date: timeStampToString(+dateId),
        id: uuid(),
      });
    }
  }, [dateId]);

  useEffect(() => {
    order.dateId && setSelectedDateId(order.dateId);
  }, [order]);

  return (
    <>
      <div className='flex gap-4 flex-wrap justify-center items-center '>
        {isLoading && (
          <div className='w-screen h-screen flex justify-center items-center'>
            <ColorRing
              visible={true}
              height='80'
              width='80'
              ariaLabel='blocks-loading'
              wrapperClass='blocks-wrapper'
              colors={['#8a0917', '#fcdd89', '#f8b26a', '#abbd81', '#8a0917']}
            />
          </div>
        )}
        {error && (
          <div className='w-screen h-screen flex justify-center items-center'>
            {error}
          </div>
        )}
        {orderDays && (
          <div className='flex flex-col justify-between items-center'>
            <div className='flex gap-7 flex-wrap justify-center items-center'>
              {orderDays
                .filter(
                  (orderDay: { availability: number }) =>
                    orderDay.availability > 0
                )
                .map((orderDay: OrderDay) => (
                  <DayCard
                    orderDay={orderDay}
                    key={orderDay.id}
                    withCheckBox
                    isSelected={selectedDateId === +orderDay.id}
                    handleOnSelect={() => {
                      setSelectedDateId(+orderDay.id);
                      updateOrder({
                        ...order,
                        dateId: orderDay.id,
                        date: orderDay.date,
                        id: uuid(),
                      });
                    }}
                  />
                ))}
            </div>

            <WizardButton
              handleClick={handleClick}
              disabled={order.date === undefined}
              warningMessage='dateSelectWarning'
            />
            {order.length > 0 && (
              <div
                className='mb-10 flex flex-col bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative w-fit mx-auto mt-[20px]'
                role='alert'
              >
                <Text tid='dateSelectInform' />
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default memo(DateForm);
