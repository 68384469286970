import { Outlet } from 'react-router-dom';
import Footer from './components/Footer';
import NavBar from './components/NavBar';
import { LanguageContextProvider } from './hooks/LanguageContext';
import { AuthContextProvider } from './components/context/AuthContext';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { SingleOrderContextProvider } from './components/context/SingleOrderContext';

function App() {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <AuthContextProvider>
        <LanguageContextProvider>
          <SingleOrderContextProvider>
            <NavBar />
            <Outlet />
            <Footer />
          </SingleOrderContextProvider>
        </LanguageContextProvider>
      </AuthContextProvider>
    </QueryClientProvider>
  );
}

export default App;
