import { useQuery } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { getAllMenu, getOrderScheduleByDateId } from "../../../api/firebase";
import { Menu } from "../../../types/menu";
import { Order } from "../../../types/order";
import { ColorRing } from "react-loader-spinner";
import {
  CHICKEN,
  SERVER_ERR_MSG,
  SAUCE,
  LARGE,
  getAmountBySize,
} from "./../../../common/constant";

interface OrderOverviewTableProps {
  dateId: string;
  orders: Order[];
}

const OrderOverviewTable = ({ dateId, orders }: OrderOverviewTableProps) => {
  const {
    isLoading,
    error: menuError,
    data: menuAllList,
  }: any = useQuery(["allMenu"], () => getAllMenu());
  const {
    isLoading: scheduleLoading,
    error: scheduleError,
    data: schedule,
  }: any = useQuery(["schedule"], () => getOrderScheduleByDateId(dateId));

  const [sauceMap, setSauceMap] = React.useState(undefined);

  useEffect(() => {
    menuAllList && setSauceMap(calculateSauseAmount());
  }, [orders, menuAllList]);

  const countAmountForMenu = (array: any[], menuId: string) => {
    let count = 0;
    array?.forEach((item: any) => {
      if (item.id === menuId) {
        const amount = getAmountBySize(item.size, item.amount);
        count += amount;
      }
    });
    return count;
  };

  const getProductDetails = (orderList: { [key: string]: Menu[] }) => {
    return (
      <div className="flex flex-col justify-start items-start">
        {Object.entries(orderList).map(([key, value], index) => (
          <div key={key + index} className="flex flex-col items-start">
            <span className="font-semibold text-sm text-gray-400 mt-2">
              {key}
            </span>
            {value?.map((item) => (
              <div className="h-fit flex gap-1 my-1" key={item.id}>
                {item.name}{" "}
                {item.size && (
                  <span className="font-bold bg-yellow text-black px-2 rounded-md">
                    {item.size === LARGE ? "L" : "S"}
                  </span>
                )}
                <span className="font-bold bg-brand text-white px-2 rounded-md">
                  {item.amount}
                </span>
                {item.isSauceApart && (
                  <span className="font-bold bg-green-500 text-white px-2 rounded-md">
                    A
                  </span>
                )}
              </div>
            ))}
            <hr />
          </div>
        ))}
      </div>
    );
  };

  const getMenuCountByMenuIdAndTime = (
    filteredOrders: Order[],
    menuId: string
  ) => {
    let menuCount = 0;
    filteredOrders?.forEach((order) => {
      menuCount += countAmountForMenu(
        Object.values(order.products).flat(),
        menuId
      );
    });
    return menuCount;
  };

  const calculateSauseAmount = () => {
    const sauceMap = new Map();
    const connectionMap = new Map();
    menuAllList[SAUCE].forEach((menu: Menu) => {
      if (menu.connection) {
        connectionMap.set(menu.id, menu.connection);
      }
    });
    menuAllList[CHICKEN].forEach((menu: Menu) => {
      sauceMap.set(menu.id, 0);
    });

    orders?.forEach((order) => {
      Object.values(order.products)
        ?.flat()
        .forEach((item: any) => {
          const amount = getAmountBySize(item.size, item.amount);
          if (sauceMap.has(item.id)) {
            sauceMap.set(item.id, sauceMap.get(item.id) + amount);
          } else {
            if (connectionMap.has(item.id)) {
              sauceMap.set(
                connectionMap.get(item.id),
                sauceMap.get(connectionMap.get(item.id)) + amount
              );
            }
          }
        });
    });

    return sauceMap;
  };

  return (
    <div className="flex flex-wrap  justify-center items-start gap-[50px]">
      {isLoading ||
        (scheduleLoading && (
          <ColorRing
            visible={true}
            height="30"
            width="30"
            ariaLabel="blocks-loading"
            wrapperClass="blocks-wrapper"
            colors={["#8a0917", "#fcdd89", "#f8b26a", "#abbd81", "#8a0917"]}
          />
        ))}
      {(menuError || scheduleError) && (
        <h2 className="text-2xl text-center">{SERVER_ERR_MSG}</h2>
      )}
      {menuAllList && schedule && (
        <div className="flex flex-col items-center">
          <h1 className="text-2xl font-semibold mb-5">Menu Overview</h1>
          <table className="table-auto w-fit border-separate border border-slate-400 ">
            <thead>
              <tr>
                {menuAllList &&
                  menuAllList[CHICKEN].map((menu: Menu) => (
                    <th
                      key={menu.id + menu.name + "menu"}
                      className="border border-slate-300 "
                    >
                      <div className="flex flex-col">
                        {menu.name.includes("&")
                          ? menu.name
                              .split(" ")
                              .map((word, index) => (
                                <span key={index + menu.id + word}>{word}</span>
                              ))
                          : menu.name}
                      </div>
                    </th>
                  ))}
              </tr>
            </thead>
            <tbody className="text-center">
              <tr>
                {menuAllList &&
                  menuAllList[CHICKEN]?.map((menu: Menu) => (
                    <td
                      key={menu.id + "menu"}
                      className="border border-slate-300 "
                    >
                      {getMenuCountByMenuIdAndTime(orders, menu.id)}
                    </td>
                  ))}
              </tr>
            </tbody>
          </table>
        </div>
      )}
      {orders && (
        <div className="flex flex-col items-center">
          <h1 className="text-2xl font-semibold mb-5">
            Sause Overview (boxes)
          </h1>
          <table className="table-auto w-fit border-separate border border-slate-400 ">
            <thead>
              <tr>
                {sauceMap &&
                  Array.from(sauceMap.keys())?.map((key: string) => (
                    <th className="border border-slate-300" key={key}>
                      {
                        menuAllList[CHICKEN].find(
                          (item: Menu) => item.id === key
                        ).name
                      }
                    </th>
                  ))}
              </tr>
            </thead>
            <tbody className="text-center">
              <tr>
                {sauceMap &&
                  Array.from(sauceMap.values()).map((count: number, index) => (
                    <td key={index} className="border border-slate-300 ">
                      {count}
                    </td>
                  ))}
              </tr>
            </tbody>
          </table>
        </div>
      )}
      {/* {orders && (
        <div className='flex flex-col items-center'>
          <h1 className='text-2xl font-semibold mb-5'>Sides Overview</h1>
          <table className='table-auto w-fit border-separate border border-slate-400 '>
            <thead>
              <tr>
                {menuAllList &&
                  menuAllList[SIDEMENU].map((menu: Menu) => (
                    <th
                      key={menu.id + menu.name + 'menu'}
                      className='border border-slate-300 '
                    >
                      <div className='flex flex-col'>
                        {menu.name.includes('&')
                          ? menu.name
                              .split(' ')
                              .map((word, index) => (
                                <span key={index + menu.id + word}>{word}</span>
                              ))
                          : menu.name}
                      </div>
                    </th>
                  ))}
              </tr>
            </thead>
            <tbody className='text-center'>
              <tr>
                {menuAllList[SIDEMENU]?.map((menu: Menu) => (
                  <td
                    key={menu.id + 'menu'}
                    className='border border-slate-300 '
                  >
                    {getMenuCountByMenuIdAndTime(orders, menu.id)}
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>
      )} */}
      {/* {orders && (
        <div className='flex flex-col items-center'>
          <h1 className='text-2xl font-semibold mb-5'>Drinks Overview</h1>
          <table className='table-auto w-fit border-separate border border-slate-400 '>
            <thead>
              <tr>
                {menuAllList &&
                  menuAllList[DRINK].map((menu: Menu) => (
                    <th
                      key={menu.id + menu.name + 'menu'}
                      className='border border-slate-300 '
                    >
                      <div className='flex flex-col'>
                        {menu.name.includes('&')
                          ? menu.name
                              .split(' ')
                              .map((word, index) => (
                                <span key={index + menu.id + word}>{word}</span>
                              ))
                          : menu.name}
                      </div>
                    </th>
                  ))}
              </tr>
            </thead>
            <tbody className='text-center'>
              <tr>
                {menuAllList[DRINK]?.map((menu: Menu) => (
                  <td
                    key={menu.id + 'menu'}
                    className='border border-slate-300 '
                  >
                    {getMenuCountByMenuIdAndTime(orders, menu.id)}
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>
      )} */}

      {orders && schedule && (
        <div className="flex flex-col items-center">
          <h1 className="text-2xl font-semibold mb-5">Pick-up Overview</h1>
          <table className="table-auto w-fit border-separate border border-slate-400 ">
            <thead>
              <tr>
                <th className="border border-slate-300 ">Timeslot</th>
                <th className="border border-slate-300 ">Pick up dtails</th>
              </tr>
            </thead>
            <tbody className="text-center">
              {Object.keys(schedule.timeslots)?.map((time) => {
                const filteredOrderByTimeslot = orders?.filter(
                  (order) => order.pickupTime === time
                );
                return (
                  <tr key={time}>
                    <td className="border border-slate-300 ">{time}</td>
                    <td className="border border-slate-300 ">
                      {filteredOrderByTimeslot?.map((order) => (
                        <div className="w-fit" key={order.id}>
                          <div className="flex gap-2  flex-col min-w-[200px] items-start">
                            <span className="font-semibold">
                              {order.orderNumber} - {order.customer.firstname}{" "}
                              {order.customer.lastname}
                            </span>
                            <span>{getProductDetails(order.products)}</span>
                          </div>
                          <hr className="border-t-2 border-brand border-dotted my-5" />
                        </div>
                      ))}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default OrderOverviewTable;
