export const accrodionDataEnglish = [
  {
    title: 'How do I place an order online?',
    desc: 'Simply go to our website and click on the "Order Online" button. Follow the instructions to select your menu items and complete your order.',
  },
  {
    title: 'Can I customize my order? ',
    desc: 'No, you cannot customize your order by adding or removing certain ingredients',
  },
  {
    title: 'How do I pay for my order? ',
    desc: 'You can pay for your order online by using iDEAL.',
  },
  {
    title: 'When can I pick up my order? ',
    desc: 'You can pick up your order at the pickup date and pickup time that you selected.',
  },
  {
    title: 'Can I change my pickup time after placing my order?',
    desc: 'Yes, you can change your pickup time by contacting our restaurant directly via phone and we will see if there are other timeslot available that suits you better.',
  },
  {
    title: 'How do I let the restaurant know I have arrived for pickup? ',
    desc: 'Once you arrive at our restaurant, you can ring the doorbell  to let us know you have arrived. We will bring your order out to you.',
  },
  {
    title: 'What if I need to cancel my order? ',
    desc: 'You can cancel your order by contacting our restaurant directly via phone, WhatsApp or email.',
  },
  {
    title: 'why will 1 euro be deducted when I cancel my order? ',
    desc: '1 euro will be deducted from the total amount, because we need to pay a transaction cost per transaction, incoming or outgoing, plus we also pay a cost for iDEAL payments.',
  },
  {
    title: 'Do you offer any specials or promotions? ',
    desc: 'No, at this moment we do not have any specials or promotions. Maybe in the future.',
  },
  {
    title: 'Can I order catering for a large event? ',
    desc: 'We don’t offer catering services for large events yet. But you can contact our restaurant directly to discuss your catering needs. We don’t have the tools yet for really big orders but we can see if we can make it happen.',
  },
];
export const accrodionDataDutch = [
  {
    title: 'Hoe plaats ik een bestelling online? ',
    desc: 'Ga eenvoudigweg naar onze website en klik op de knop "Online bestellen". Volg de instructies om uw menu-items te selecteren en uw bestelling te voltooien.',
  },
  {
    title: 'Kan ik mijn bestelling aanpassen? ',
    desc: 'Nee, u kunt uw bestelling niet aanpassen door bepaalde ingrediënten toe te voegen of te verwijderen.',
  },
  {
    title: 'Hoe kan ik betalen voor mijn bestelling? ',
    desc: 'U kunt online betalen door gebruik te maken van iDEAL.',
  },
  {
    title: 'Wanneer kan ik mijn bestelling ophalen? ',
    desc: 'U kunt uw bestelling ophalen op de afhaaldatum en -tijd die u heeft geselecteerd.',
  },
  {
    title:
      'Kan ik mijn afhaaltijd wijzigen nadat ik mijn bestelling heb geplaatst? ',
    desc: 'Ja, u kunt uw afhaaltijd wijzigen door rechtstreeks contact op te nemen met ons restaurant via telefoon en zullen samen met u kijken naar een ander passend tijdslot.',
  },
  {
    title:
      'Hoe laat ik het restaurant weten dat ik ben gearriveerd voor het ophalen?',
    desc: 'Zodra u bij ons restaurant aankomt, kunt u op de deurbel drukken om ons te laten weten dat u bent gearriveerd. Wij brengen uw bestelling naar u toe.',
  },
  {
    title: 'Wat als ik mijn bestelling moet annuleren? ',
    desc: 'U kunt uw bestelling annuleren door rechtstreeks contact op te nemen met ons restaurant via telefoon, WhatsApp of e-mail.',
  },
  {
    title: 'Waarom wordt er €1,- ingehouden wanneer ik mijn order annuleer?',
    desc: 'Bij zowel inkomende, als uitgaande transacties worden er kosten gemaakt. Bij annulering van een bestelling, houden we €1,- in om deze transactiekosten te dekken.',
  },
  {
    title: 'Bied u speciale aanbiedingen of promoties aan?',
    desc: 'Nee, op dit moment bieden we geen speciale aanbiedingen of promoties aan. Dit kan in de toekomst nog veranderen, dus houd onze website goed in de gaten!',
  },
  {
    title: 'Kan ik catering bestellen voor een groot evenement? ',
    desc: 'Op dit moment bieden we geen catering voor grote evenementen. Wanneer u hier vragen over heeft, kunt u contact opnemen met ons restaurant om uw behoeften te bespreken. We kunnen dan samen kijken of deze behoefte te realiseren is. Houd er rekening mee dat wij nog niet de tools hebben om grote bestellingen aan te nemen, maar we kunnen altijd even met u meekijken en de opties bespreken.',
  },
];
