import React, { useState, memo, useEffect } from 'react';
import DateForm from './dateSelection/DateForm';
import Stepper from '../../../components/Stepper';
import TimeForm from './timeSelection/TimeForm';
import MenuForm from './menuSelction/MenuForm';
import CustomerForm from './CustomerForm';
import PaymentForm from './PaymentForm';
import { useNavigate } from 'react-router-dom';
import { useSingleOrderContext } from '../../../components/context/SingleOrderContext';

const Wizard = () => {
  const navigate = useNavigate();
  const { order, updateOrder, setRecentFinalStep, recentFinalStep } =
    useSingleOrderContext();
  const stepArray = [
    'selectDate',
    'selectMenu',
    'selectTime',
    'yourDetails',
    'payment',
  ];
  const [currentStep, setCurrentStep] = useState(1);

  useEffect(() => {
    if (order.products && Object.values(order.products).flat().length === 0) {
      setCurrentStep(1);
    }
    if (
      order.products &&
      Object.values(order.products).flat().length > 0 &&
      currentStep !== recentFinalStep
    ) {
      setCurrentStep(recentFinalStep);
    }
  }, [order, recentFinalStep]);

  const toHome = () => {
    navigate('/');
  };

  const handleClick = (clickType: string) => {
    let newStep = currentStep;
    if (clickType === 'next') {
      newStep++;
    } else {
      newStep--;
    }
    if (clickType === 'before' && newStep < 1) {
      toHome();
      return;
    }
    if (newStep > 0 && newStep <= stepArray.length) {
      setCurrentStep(newStep);
      setRecentFinalStep(newStep);
    }
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  const backToFirstStep = () => {
    updateOrder([]);
    navigate('/order');
    setCurrentStep(1);
  };

  const getStepContent = (stepIndex: number) => {
    switch (stepIndex) {
      case 1:
        return <DateForm handleClick={handleClick} />;
      case 2:
        return (
          <MenuForm handleClick={handleClick} backToFirst={backToFirstStep} />
        );
      case 3:
        return <TimeForm handleClick={handleClick} />;
      case 4:
        return <CustomerForm handleClick={handleClick} />;
      case 5:
        return (
          <PaymentForm
            handleClick={handleClick}
            backToFirst={backToFirstStep}
          />
        );
      default:
        return 'Unknown stepIndex';
    }
  };
  return (
    <div className='mt-10 w-[100%] border shadow-xl rounded-2xl p-7  flex flex-col  md:w-[80%] gap-7 h-fit bg-white'>
      <div className='container horizontal mt-5 mb-12'>
        <Stepper steps={stepArray} currentStepNumber={currentStep} />
      </div>
      <div className='container'>{getStepContent(currentStep)}</div>
    </div>
  );
};

export default memo(Wizard);
