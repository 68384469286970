import React, { createContext, useContext, useState, useEffect } from 'react';
import { logout, onUserStateChange } from '../../api/firebase';
import { login } from './../../api/firebase';

const AuthContext = createContext<any>({});

export function AuthContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [user, setUser] = useState();

  useEffect(() => {
    onUserStateChange(setUser);
  }, []);

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuthContext() {
  return useContext(AuthContext);
}
