import React, { useEffect } from "react";
import { FaGoogle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { login, logout } from "../api/firebase";
import { useAuthContext } from "../components/context/AuthContext";

const Login = () => {
  const { user } = useAuthContext();
  const navigate = useNavigate();

  const gotoAdmin = () => {
    navigate("/admin");
  };

  const handleLogin = async () => {
    const result = await login();
    if (result.isAdmin) {
      gotoAdmin();
    }
  };

  useEffect(() => {
    if (user?.isAdmin) {
      gotoAdmin();
    }
  }, [user]);
  return (
    <div className="mt-[90px]">
      <div className="flex flex-col justify-center items-center h-[90vh]">
        {!user && (
          <button
            className="flex rounded-lg bg-brand justify-center items-center text-white gap-5 py-3 px-6"
            onClick={handleLogin}
          >
            <FaGoogle />
            Admin Login
          </button>
        )}

        {user && <button onClick={logout}>Admin Logout</button>}
      </div>
    </div>
  );
};

export default Login;
