import React from 'react';
import { CHICKEN } from '../../../common/constant';
import { toEuro } from '../../../common/utils';
import Text from '../../../components/Text';
import { Item } from '../../../types/item';

interface ProductDetailsProps {
  products: { chicken: Item[] };
}

const ProductDetails = ({ products }: ProductDetailsProps) => {
  return (
    <table className='w-full'>
      <thead>
        <tr className='text-left h-[20px] bg-gray-100 rounded-xl'>
          <th>
            <Text tid='product' />
          </th>
          <th className='w-6'>
            <Text tid='subTotal' />
          </th>
        </tr>
      </thead>
      <tbody>
        {products[CHICKEN].map((item: any, index: number) => (
          <tr key={item.menuName + index}>
            <td className='flex gap-5'>
              <span>{item.menuName} </span>
              <span className='font-bold'>x {item.amount}</span>
            </td>
            <td>{toEuro(+item.price * item.amount)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ProductDetails;
