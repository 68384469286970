import React, { useState, useEffect } from 'react';
import Alert from '../../../components/Alert';
import Text from '../../../components/Text';
type WizardButtonProps = {
  handleClick: (step: string) => void;
  disabled: boolean;
  warningMessage: string;
  middleCallback?: () => void;
};
function WizardButton({
  handleClick,
  disabled,
  warningMessage,
  middleCallback,
}: WizardButtonProps) {
  const [showWarning, setShowWarning] = useState(false);
  useEffect(() => {
    !disabled && setShowWarning(false);
  }, [disabled]);

  const handleNext = (step: string) => {
    middleCallback && middleCallback();
    handleClick(step);
  };
  return (
    <>
      <div className='container flex justify-center gap-10  mt-[100px]'>
        <button
          onClick={() => handleClick('before')}
          className='btn-primary transition duration-300 ease-in-out focus:outline-none focus:shadow-outline bg-brand hover:bg-brandHover text-white font-normal py-2 px-4 mr-1 rounded'
        >
          <Text tid='previous' />
        </button>
        <span onClick={() => setShowWarning(true)}>
          <button
            disabled={disabled}
            onClick={() => handleNext('next')}
            className={`btn-outline-primary transition duration-300 ease-in-out focus:outline-none focus:shadow-outline border border-brand hover:bg-brand text-brand hover:text-white font-normal py-2 px-4 rounded
      ${
        disabled &&
        'transition-none opacity-40 hover:bg-white hover:text-brand hover:bg-opacity-0 pointer-events-none'
      }`}
          >
            <Text tid='next' />
          </button>
        </span>
      </div>
      {showWarning && (
        <Alert subject='Informational message' description={warningMessage} />
      )}
    </>
  );
}

export default WizardButton;
