import React, { useState, useContext, useEffect } from 'react';
import { LanguageContext } from '../hooks/LanguageContext';
import {
  accrodionDataDutch,
  accrodionDataEnglish,
} from './../data/accordionData';
import AccordionItem from './AccordionItem';

const Accordion = () => {
  const { userLanguage } = useContext(LanguageContext);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(undefined);

  const toggle = (index: number) => {
    if (open === index) {
      return setOpen(undefined);
    }
    return setOpen(index);
  };

  useEffect(() => {
    userLanguage === 'en'
      ? setData(accrodionDataEnglish)
      : setData(accrodionDataDutch);
  }, [userLanguage]);

  return (
    <div id='accordionExample'>
      {data.map((row, index) => (
        <AccordionItem
          open={open === index}
          key={index}
          title={row.title}
          desc={row.desc}
          toggle={() => toggle(index)}
        />
      ))}
    </div>
  );
};

export default Accordion;
