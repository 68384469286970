import { useQueries } from '@tanstack/react-query';
import {
  getOrderDaysFromToday,
  getOrderScheduleFromToday,
} from '../api/firebase';

function useOrderDays() {
  const results = useQueries({
    queries: [
      {
        queryKey: ['orderDays'],
        queryFn: () => getOrderDaysFromToday(),
      },
      {
        queryKey: ['orderSchedule'],
        queryFn: () => getOrderScheduleFromToday(),
      },
    ],
  });

  const isLoading = results.some((result) => result.isLoading);
  const error = results.some((result) => result.error);

  const data =
    !isLoading && !error
      ? results[0].data.map((orderDay: any) => {
          const availability = results[1].data.hasOwnProperty(`${orderDay.id}`)
            ? results[1].data[`${orderDay.id}`]['availableAmount']
            : 0;
          return {
            ...orderDay,
            availability,
            totalAvailablity: orderDay.availability,
          };
        })
      : [];

  return { isLoading, error, data };
}

export default useOrderDays;
