import React, { memo } from 'react';
import DateFormatter from './DateFormatter';
import Text from './Text';
import { FaChevronRight } from 'react-icons/fa';
import { OrderDay } from '../types/orderDay';

type DayCardProps = {
  orderDay: OrderDay;
  withCheckBox?: boolean;
  isSelected?: boolean;
  handleOnSelect?: () => void;
  handleCardClick?: (id: string, date?: string) => void;
};

const DayCard = ({
  orderDay,
  withCheckBox,
  handleCardClick,
  isSelected,
  handleOnSelect,
}: DayCardProps) => {
  const isSoldOut = +orderDay.availability <= 0;

  const onCardClick = () => {
    if (withCheckBox) {
      !isSoldOut && handleOnSelect();
    } else {
      !isSoldOut && handleCardClick(orderDay.id, orderDay.date);
    }
  };

  return (
    <div
      className={`flex flex-col justify-center items-center shadow-lg rounded-xl gap-3 transition ease-in-out  border-2 overflow-hidden p-3   ${
        !isSoldOut ? 'hover:scale-110 bg-white cursor-pointer' : 'bg-gray-200'
      } ${withCheckBox && isSelected && 'bg-yellow'}`}
      onClick={onCardClick}
    >
      <div className='font-semibold text-2xl  text-center'>
        {/* @ts-expect-error Server Component */}
        <DateFormatter date={orderDay.date} />
      </div>
      {withCheckBox ? (
        <div className='flex flex-col gap-2'>
          {isSoldOut ? (
            <Text tid='soldOut' />
          ) : (
            <span className='text-brand'>
              <Text tid={'available'} />
            </span>
          )}
          <input
            type='checkbox'
            checked={isSelected}
            onChange={onCardClick}
            disabled={isSoldOut}
          />
        </div>
      ) : (
        <button
          type='button'
          disabled={isSoldOut}
          className={`'text-white rounded-lg font-bold flex items-center justify-center gap-3 bg-brand p-2 text-white ${
            isSoldOut && 'bg-gray-400 text-white '
          }`}
        >
          {isSoldOut ? (
            <Text tid='soldOut' />
          ) : (
            <>
              <Text tid='orderButton' />
              <FaChevronRight />
            </>
          )}
        </button>
      )}
    </div>
  );
};

export default memo(DayCard);
