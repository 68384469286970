import React from 'react';
import Text from './Text';

function Alert({ subject, description }: any) {
  return (
    <div
      className='flex flex-col bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative w-fit mx-auto mt-[20px]'
      role='alert'
    >
      <strong className='font-bold'>{subject}</strong>
      <span className='block sm:inline '>
        <Text tid={description} />
      </span>
      <span className='absolute top-0 bottom-0 right-0 px-4 py-3'></span>
    </div>
  );
}

export default Alert;
