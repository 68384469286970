import React, { useEffect, useState } from 'react';
import DateFormatter from '../../../../components/DateFormatter';
import TimeSlots from './TimeSlots';
import WizardButton from '../WizardButton';
import { useSingleOrderContext } from '../../../../components/context/SingleOrderContext';

const TimeForm = ({ handleClick }: { handleClick: (step: string) => void }) => {
  const { order, updateOrder } = useSingleOrderContext();
  const [disableNext, setDisableNext] = useState(true);

  useEffect(() => {
    !!order.pickupTime ? setDisableNext(false) : setDisableNext(true);
  }, [order]);

  const handleSlotClick = (slot: string) => {
    updateOrder({
      ...order,
      pickupTime: slot,
    });
  };

  return (
    <div>
      <div className='mb-[5rem]' key={order.dateId}>
        <div className='mb-[3rem]'>
          <h2 className='text-3xl my-2 bg-gradient-to-r from-brand font-bold text-white p-2 rounded-md w-[100%] mx-auto'>
            {/* @ts-expect-error Server Component */}
            <DateFormatter date={order.date} />
          </h2>
        </div>
        <TimeSlots dateId={order.dateId} handleSlotClick={handleSlotClick} />
      </div>
      <WizardButton
        handleClick={handleClick}
        disabled={disableNext}
        warningMessage='timeSelectWarning'
      />
    </div>
  );
};

export default TimeForm;
