import React, { useState, useEffect } from "react";
import DayCard from "../components/DayCard";
import Text from "../components/Text";
import {
  FaAngleDown,
  FaEnvelope,
  FaFacebook,
  FaInstagram,
  FaPhone,
  FaWhatsapp,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { OrderDay } from "../types/orderDay";
import { ColorRing } from "react-loader-spinner";
import useOrderDays from "./../hooks/useOrderDays";
import PickUpInfo from "../components/PickUpInfo";
import { listenSiteMode } from "../api/firebase";

const Home = () => {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 720);
  const { data: orderDays, isLoading, error } = useOrderDays();
  const [orderMode, setOrderMode] = useState<boolean>(undefined);
  const [modeError, setModeError] = useState(undefined);

  const handleResize = () => {
    if (window.innerWidth < 720) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  const handleCardClick = (id: string, date: string) => {
    navigate(`/order/${id}`);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    let unsubscribeForOrderMode: () => void;

    listenSiteMode(
      (orderMode) => {
        setOrderMode(orderMode);
      },
      (error) => {
        setModeError(error);
      }
    ).then((unsub) => {
      unsubscribeForOrderMode = unsub;
    });
  }, []);

  return (
    <>
      {isLoading && (
        <div className="w-screen h-screen flex justify-center items-center">
          <ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperClass="blocks-wrapper"
            colors={["#8a0917", "#fcdd89", "#f8b26a", "#abbd81", "#8a0917"]}
          />
        </div>
      )}
      {error && (
        <div className="w-screen h-screen flex justify-center items-center">
          {error}
        </div>
      )}
      {orderDays && (
        <div className="flex flex-col sections">
          <section className="flex  max-md:flex-col showcase ">
            <div className="flex flex-col justify-center items-center  min-h-screen w-full ">
              <div className="flex flex-col justify-center items-center gap-4 p-2 mb-10">
                <h1 className="text-4xl font-bold text-brand text-center mt-[100px] sm:text-5xl ">
                  <Text tid="showcaseTitle" />
                </h1>
                <span className="text-xl text-center w-2/3 sm:text-2xl">
                  <Text tid="showcaseDescription" />
                </span>
              </div>
              <div className="flex gap-10 justify-evenly p-2 mb-5">
                {orderDays
                  .slice(0, isMobile ? 2 : 3)
                  .map((orderDay: OrderDay) => (
                    <DayCard
                      orderDay={orderDay}
                      key={orderDay.id}
                      handleCardClick={handleCardClick}
                    />
                  ))}
              </div>

              {orderMode ? (
                <a
                  href="#orderDays"
                  className="flex flex-col items-center py-2 px-4 text-xl font-extrabold underline underline-offset-3"
                >
                  <Text tid="moreDays" />
                  <FaAngleDown />
                </a>
              ) : (
                <a
                  href="#foodtruck"
                  className="flex flex-col items-center py-2 px-4 text-xl font-extrabold underline underline-offset-3"
                >
                  <Text tid="foodtruckBtn" />
                  <FaAngleDown />
                </a>
              )}
            </div>
          </section>
          {orderMode ? (
            <section
              id="orderDays"
              className="bg-brand text-white text-center min-h-screen"
            >
              <div className="h-fit min-h-screen flex flex-col items-center justify-around max-w-screen-lg mx-auto">
                <h2 className="text-4xl font-bold mt-[90px] sm:text-5xl">
                  <Text tid="orderDays" />
                </h2>
                <div className="flex flex-wrap items-center gap-5 text-black justify-center my-6">
                  {orderDays.map((orderDay: OrderDay) => (
                    <DayCard
                      orderDay={orderDay}
                      key={orderDay.id}
                      handleCardClick={handleCardClick}
                    />
                  ))}
                  {orderDays?.length === 0 && (
                    <h2 className="text-3xl text-white">
                      <Text tid="noOrderDay" />
                    </h2>
                  )}
                </div>
                <span className="w-full text-right pb-3">
                  <Text tid="beContinued" />
                </span>
              </div>
            </section>
          ) : (
            <section
              id="foodtruck"
              className="bg-brand text-white text-center min-h-screen py-[30px]"
            >
              <div className="h-fit min-h-screen flex flex-col items-center max-w-screen-lg mx-auto">
                <h2 className="text-4xl font-bold mt-[30px] sm:mt-[90px] sm:text-5xl">
                  <Text tid="foodtruck" />
                </h2>
                <div className="flex flex-wrap items-center gap-5 text-black justify-center my-6 mt-[70px]">
                  <img
                    src={`/foodtruck.jpg`}
                    alt={"foodtruck"}
                    className="w-[95%] h-full rounded-xl shadow-md sm:w-[500px] drop-shadow-xl"
                  />
                  <div className="w-[95%] sm:w-[500px] text-white flex flex-col gap-12">
                    <p className="text-lg text-left">
                      <Text tid="foodtruckFirstSentence" />
                      <br />
                      <br />
                      <Text tid="foodtruckSecondSentence" />
                    </p>
                    <div className="flex justify-center gap-5">
                      <a
                        target="_blank"
                        href="https://www.facebook.com/profile.php?id=100090776049222"
                        className="flex  items-center gap-1 text-xl"
                        rel="noreferrer"
                      >
                        <FaFacebook size={"2em"} />
                        <Text tid="facebook" />
                      </a>
                      <a
                        target="_blank"
                        href="https://instagram.com/chimeknl?igshid=ZDdkNTZiNTM="
                        className="flex  items-center gap-1 text-xl"
                        rel="noreferrer"
                      >
                        <FaInstagram size={"2rem"} />
                        <Text tid="instagram" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
          {orderMode ? (
            <section className="flex flex-col max-h-screen h-screen bg-yellow justify-center px-5 md:px-[10rem]">
              <PickUpInfo />
            </section>
          ) : (
            <section className="flex flex-col max-h-screen h-screen bg-yellow justify-center px-5 md:px-[10rem]">
              <div className="h-fit flex flex-col items-center max-w-screen-lg mx-auto">
                <h2 className="text-brand text-4xl font-bold mt-[40px] sm:text-5xl">
                  <Text tid="catering" />
                </h2>
                <p className="text-lg text-center mt-[20px]">
                  <Text tid="cateringFirstSentence" />
                  <br />
                  <Text tid="cateringSecondSentence" />
                  <br />
                  <Text tid="cateringThirdSentence" />
                </p>
                <div className="flex gap-[1rem] sm:gap-[8rem] w-full justify-center flex-wrap my-10 mb-[40px]">
                  <div className="flex flex-col gap-2 justify-center items-center min-w-[10rem]">
                    <FaEnvelope color="#8a0917" size={"5rem"} />
                    <h1 className="text-2xl font-extrabold">Email</h1>
                    <a
                      className="text-lg"
                      href="https://mail.google.com/mail/?view=cm&fs=1&to=info@chimek.nl"
                    >
                      info@chimek.nl
                    </a>
                  </div>
                  <div className="flex flex-col gap-2 justify-center items-center min-w-[10rem] ">
                    <FaPhone color="#8a0917" size={"5rem"} />
                    <h1 className="text-2xl font-extrabold">
                      <Text tid="phone" />
                    </h1>
                    <a
                      href="https://api.whatsapp.com/send?phone=31641685401"
                      className="flex gap-2"
                    >
                      <FaWhatsapp size={"1.3rem"} /> 0641685401
                    </a>
                  </div>
                </div>
              </div>
            </section>
          )}
        </div>
      )}
    </>
  );
};

export default Home;
